import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useDispatch } from "react-redux";

import Card from "src/components/atoms/Card/Card";
import Button from "src/components/atoms/Button/Button";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import ProgressResultDetailsCard from "src/components/molecules/progress/ProgressResultDetailsCard";
import {
  setSelectedDetailsMasterId,
  setSelectedVital,
} from "src/store/slices/progressSlice";
import { RightArrowIcon } from "src/components/atoms/Icons/RightArrowIcon";
import { ProgressType } from "src/types/progress";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import LogVitalSheet from "src/components/molecules/progress/LogVitalSheet";
import LabResultImage from "src/components/atoms/Icons/Progress/LabResult";
import CardioVascularImage from "src/components/atoms/Icons/Progress/CardioVascular";
import BodyCompositionImage from "src/components/atoms/Icons/Progress/BodyComposition";
import HeartImage from "src/components/atoms/Icons/Progress/HeartIcon";
import WellBeing from "src/components/atoms/Icons/Progress/WellBeing";
import InfoIcon from "src/components/atoms/Icons/InfoIcon";
import CloseCircleIcon from "src/components/atoms/Icons/CloseCircleIcon";

const VitalProgressDetailsPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef<HTMLDivElement>(null);

  const { selectedProgress } = useSelector(
    (state: RootState) => state.progress
  );
  const theme = useSelector((state: RootState) => state.theme);
  const [selectedVital, setSelectedVitals] = useState<ProgressType>();
  const [logVitalSheetOpen, setLogVitalSheetOpen] = useState<boolean>(false);

  const handleNextOfSelectedProgress = (category: ProgressType) => {
    dispatch(setSelectedVital(category));
    dispatch(
      setSelectedDetailsMasterId({
        selectedProgressMasterId: category.readings_master_id,
        manualAddFlag: category.manualAddFlag,
      })
    );
    navigate("/progress/vitals/progress-details/recorded-data");
  };

  const togglePopup = () => setIsOpen(!isOpen);

  const getCustomProgressImage = (key: string) => {
    switch (key) {
      case "Lab Report Results":
        return (
          <LabResultImage
            height={120}
            width={120}
            primary={theme.colors["--color-progress-icon"]}
            secondary={"#FFFFFF"}
          />
        );
      case "Cardiovascular Health":
        return (
          <CardioVascularImage
            height={120}
            width={120}
            primary={theme.colors["--color-progress-icon"]}
            secondary={"#FFFFFF"}
          />
        );
      case "Body Composition":
        return (
          <BodyCompositionImage
            height={120}
            width={120}
            primary={theme.colors["--color-progress-icon"]}
            secondary={"#FFFFFF"}
          />
        );
      case "Advanced Health Metrics":
        return (
          <HeartImage
            height={120}
            width={120}
            primary={theme.colors["--color-progress-icon"]}
            secondary={"#FFFFFF"}
          />
        );
      case "Well-being and Stress":
        return (
          <WellBeing
            height={120}
            width={120}
            primary={theme.colors["--color-progress-icon"]}
            secondary={"#FFFFFF"}
          />
        );
    }
  };

  return (
    <div className=" bg-background-light-blue h-full">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <ProgressBackIcon onClick={() => navigate("/progress/vitals")} />
          </div>
        }
        component2={
          <div>
            <section className="p-6 flex flex-col justify-center items-center gap-4">
              <Card className="flex !rounded-4xl !p-0">
                {/* <img
                  src={selectedProgress?.icon_url}
                  alt={selectedProgress?.category_name}
                  className="w-23 h-23 object-cover"
                /> */}
                <div className="">
                  {getCustomProgressImage(
                    selectedProgress?.category_name ?? "Cardiovascular Health"
                  )}
                </div>
              </Card>
              <div className="flex flex-col gap-1.5 justify-center items-center">
                <p className="font-semibold text-base font-poppins text-text-primary">
                  {selectedProgress?.category_name}
                </p>
                <p className="text-sm text-text-secodary flex font-poppins justify-center text-center">
                  {selectedProgress?.categoryDescription}
                </p>
              </div>
            </section>

            <section className="p-6 flex flex-col gap-4 overflow-x-hidden overflow-[inherit]">
              {selectedProgress &&
                selectedProgress?.data?.length > 0 &&
                selectedProgress.data.map(
                  (categoryReading: ProgressType, index: number) => (
                    <React.Fragment key={index}>
                      {categoryReading?.reading_value &&
                      categoryReading.reading_value > 0 ? (
                        <ProgressResultDetailsCard
                          key={categoryReading.keys}
                          progressTitle={categoryReading?.reading_name}
                          progressUnit={categoryReading?.unit}
                          vitalBg={categoryReading?.color_code}
                          vitalTextColor="text-color-extra-dark-green"
                          loggedDate={categoryReading?.reading_datetime}
                          info={categoryReading.information}
                          handleOnclick={() =>
                            handleNextOfSelectedProgress(categoryReading)
                          }
                          validation={categoryReading?.validation}
                          valueObject={categoryReading?.reading_value_data}
                          startRange={categoryReading?.minLimit}
                          endRange={categoryReading?.maxLimit}
                          activeRange={categoryReading?.reading_value}
                        />
                      ) : ["bloodpressure"].includes(categoryReading.keys) &&
                        categoryReading.reading_value_data ? (
                        <>
                          <ProgressResultDetailsCard
                            key={categoryReading?.keys}
                            progressTitle={categoryReading?.reading_name}
                            progressUnit={categoryReading?.unit}
                            vitalBg={categoryReading?.color_code}
                            vitalTextColor="text-color-extra-dark-green"
                            loggedDate={categoryReading?.reading_datetime}
                            info={categoryReading?.information}
                            readingLimits={categoryReading?.readingLimits}
                            handleOnclick={() =>
                              handleNextOfSelectedProgress(categoryReading)
                            }
                            valueObject={categoryReading?.reading_value_data}
                            renderObject={true}
                            startRange={categoryReading?.minLimit}
                            endRange={categoryReading?.maxLimit}
                            validation={categoryReading?.validation}
                            activeRange={categoryReading?.reading_value}
                          />
                        </>
                      ) : (
                        <>
                          {categoryReading &&
                          categoryReading.manualAddFlag === 1 ? (
                            <>
                              <section className="">
                                <Card className="p-4 !rounded-3xl flex flex-col gap-4">
                                  <div className="flex justify-between">
                                    <div className="flex items-center   font-semibold text-base font-poppins text-text-primary">
                                      <p className="max-w-48  truncate">
                                        {" "}
                                        {categoryReading.reading_name}
                                      </p>
                                      <div
                                        className="relative"
                                        ref={iconRef}
                                        onClick={(e) => {
                                          setSelectedVitals(categoryReading);
                                          e.stopPropagation();
                                          togglePopup();
                                        }}
                                      >
                                        {categoryReading?.information &&
                                          categoryReading?.information.length >
                                            0 && (
                                            <InfoIcon
                                              className="ml-2"
                                              color={"gray"}
                                            />
                                          )}
                                      </div>
                                    </div>
                                    <div className="bg-color-light-red text-color-dark-red rounded-full font-poppins text-xs py-1 px-3">
                                      Not Logged
                                    </div>
                                  </div>
                                  <Button
                                    label="Log Your Vital"
                                    type="button"
                                    variant="ternary"
                                    className="w-full"
                                    onClick={() => {
                                      setSelectedVitals(categoryReading);
                                      setLogVitalSheetOpen(true);
                                    }}
                                  >
                                    <RightArrowIcon
                                      color={theme.colors["--color-dark-blue"]}
                                    />
                                  </Button>
                                </Card>
                              </section>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </React.Fragment>
                  )
                )}
            </section>
          </div>
        }
      />
      {selectedVital && (
        <BottomSheetSelect
          open={logVitalSheetOpen}
          close={() => {
            setLogVitalSheetOpen(false);
          }}
        >
          <LogVitalSheet
            VitalData={selectedVital}
            onClose={() => setLogVitalSheetOpen(false)}
          />
        </BottomSheetSelect>
      )}

      <BottomSheetSelect open={isOpen} close={() => setIsOpen(false)}>
        <section className="p-4 flex justify-between ">
          <h1 className="text-xl font-bold text-text-secodary">
            {selectedVital?.reading_name}
          </h1>
          <div onClick={() => setIsOpen(false)}>
            <CloseCircleIcon />
          </div>
        </section>
        <hr className="w-full h-[2px] bg-background" />
        <section className="p-4">{selectedVital?.information}</section>
      </BottomSheetSelect>
    </div>
  );
};
export default VitalProgressDetailsPage;
