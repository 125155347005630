import moment from "moment";
import { useLocation } from "react-router-dom";

export const getPlatform = () => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform") || "default";

  return platform;
};

export const removeQueryParamsFromURL = (params: string[]) => {
  const url = new URL(window.location.href);
  params.forEach((p) => url.searchParams.delete(p));
  window.history.replaceState(null, "", url.toString());
};

/**
 * Bind query parameters to the endpoint
 * @param {String} url
 * @param {Object} queryParams
 * @returns
 */
export const bindQueryParams = (url: string, queryParams: object) => {
  const queryString = Object.entries(queryParams)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  return `${url}?${queryString}`;
};

export const getGreetingMsg = () => {
  const currentHour = new Date().getHours();
  let greeting;
  if (currentHour >= 5 && currentHour < 12) {
    greeting = "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }
  return greeting;
};

export const useUrlParams = <T = any>() => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlParams = Object.fromEntries(queryParams.entries());

  let parsedParams: T | null = null;

  if (urlParams?.data) {
    try {
      parsedParams = JSON.parse(decodeURIComponent(urlParams.data)) as T;
    } catch (error) {
      console.error("Failed to parse URL parameters:", error);
    }
  }

  return parsedParams;
};

/**
 *
 * @param patientPlans
 * @returns true -> Free User
 *          false -> paid user
 */
export const checkUserPlanFree = (patientPlans: any[]): boolean => {
  if (!patientPlans || patientPlans.length === 0) {
    return true;
  } else if (patientPlans[0]?.plan_type === "Free") {
    return true;
  }
  return false;
};

export const activePlanFound = (
  patientPlans: any[],
  planId: string
): boolean => {
  if (!patientPlans || patientPlans.length === 0) {
    return false;
  } else {
    const plan = (patientPlans ?? [])?.find(
      (item) => item?.plan_master_id === planId
    );
    if (!plan || (!!plan && plan?.is_active === "N")) return false;
    return true;
  }
};

export const formatFeetAndInchesToString = (
  feet: string,
  inches: string
): string => {
  const feetValue = parseFloat(feet) || 0;
  const inchesValue = parseFloat(inches) || 0;

  const totalHeight = feetValue * 30.48 + inchesValue * 2.54;

  const formattedHeight = totalHeight.toFixed(2);

  return formattedHeight;
};

export const convertToFeetInches = (cm: number) => {
  const totalInches = cm * 0.393701;
  const feet = Math.floor(totalInches / 12);
  const inches = (totalInches % 12).toFixed(2);
  return { feet, inches };
};

export const calculateAge = (dob: string, formate: string) => {
  const birthDate = moment(dob, formate);
  const today = moment();
  return today.diff(birthDate, "years");
};

export const validateDecimal = (value: string) => /^\d*\.?\d*$/.test(value);
