import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";

import Card from "src/components/atoms/Card/Card";
import Button from "src/components/atoms/Button/Button";
import TextInput from "src/components/atoms/TextInput";
import SearchIcon from "src/assets/icons/common/search-icon.svg";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import CloseCircleIcon from "src/components/atoms/Icons/CloseCircleIcon";
import { RightArrowIcon } from "src/components/atoms/Icons/RightArrowIcon";
import ProgressResultDetailsCard from "src/components/molecules/progress/ProgressResultDetailsCard";
import { ProgressType } from "src/types/progress";
import { useDispatch } from "react-redux";
import {
  progressCategoryRequest,
  setRedirectFromSearch,
  setSelectedDetailsMasterId,
  setSelectedVital,
} from "src/store/slices/progressSlice";
import InfoIcon from "src/components/atoms/Icons/InfoIcon";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import LogVitalSheet from "src/components/molecules/progress/LogVitalSheet";
import { toast } from "react-toastify";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";

export const SearchProgressDetailsPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const inputRef = useRef<HTMLInputElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [showLoader, setshowLoader] = useState(false);
  const [filteredLogData, setFilteredLogData] = useState<any>();
  const theme = useSelector((state: RootState) => state.theme);
  const [selectedVital, setSelectedVitals] = useState<ProgressType>();
  const [logVitalSheetOpen, setLogVitalSheetOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const { allProgress } = useSelector((state: RootState) => state.progress);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const filteredlog = allProgress?.flatMap((item) =>
        item.data.filter((patientlog) =>
          patientlog.reading_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
      setFilteredLogData(filteredlog);
    } else {
      setFilteredLogData("");
    }
  }, [searchTerm, allProgress]);
  const fetchData = () => {
    const payload = {
      page: "1",
      version: "v1",
    };

    new Promise((resolve, reject) => {
      setshowLoader(true);
      dispatch(
        progressCategoryRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((result) => {
        setshowLoader(false);
      })
      .catch((error) => {
        // dispatch(hideLoader());
        toast.error(error);
        setshowLoader(false);
      })
      .finally(() => {
        setshowLoader(false);
      });
  };
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const handleClickOfRightIcon = () => {
    setSearchTerm("");
  };

  const onClickOfBack = () => {
    navigate("/progress/vitals");
  };

  const handleNextOfSelectedProgress = (category: ProgressType) => {
    dispatch(setSelectedVital(category));
    dispatch(
      setSelectedDetailsMasterId({
        selectedProgressMasterId: category.readings_master_id,
        manualAddFlag: category.manualAddFlag,
      })
    );
    navigate("/progress/vitals/progress-details/recorded-data?isSearch=true");
  };

  const togglePopup = () => setIsOpen(!isOpen);
  return (
    <div className="h-screen bg-background-light-blue ">
      {showLoader ? (
        <div className="h-full w-full flex items-center justify-center">
          <SpinnerLoader />
        </div>
      ) : (
        <>
          <ExpandableDivs
            showExpandBtn={false}
            component1={
              <div className="flex bg-white  justify-center px-4 items-center w-full">
                <button onClick={onClickOfBack}>
                  <RightArrowIcon
                    className="h-7 w-7 rotate-180"
                    color={theme.colors["--color-text-primary"]}
                  />
                </button>
                <TextInput
                  className="w-full pl-4"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  ref={inputRef}
                  placeholder="Search by vital name"
                  readOnly={false}
                  leftIcon={
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      className="w-5 h-5"
                    />
                  }
                  rightIcon={<CloseCircleIcon width={20} height={20} />}
                  onClickOfRightIcon={handleClickOfRightIcon}
                />
              </div>
            }
            component2={
              <>
                <section className="p-6 flex flex-col gap-4">
                  {filteredLogData && filteredLogData.length ? (
                    filteredLogData.map(
                      (categoryReading: ProgressType, index: number) => (
                        <React.Fragment key={index}>
                          {categoryReading?.reading_value &&
                          categoryReading.reading_value > 0 ? (
                            <ProgressResultDetailsCard
                              key={categoryReading?.keys}
                              progressTitle={categoryReading?.reading_name}
                              progressUnit={categoryReading?.unit}
                              vitalBg={categoryReading?.color_code}
                              vitalTextColor="text-color-extra-dark-green"
                              loggedDate={categoryReading?.reading_datetime}
                              info={categoryReading?.information}
                              validation={categoryReading?.validation}
                              handleOnclick={() =>
                                handleNextOfSelectedProgress(categoryReading)
                              }
                              valueObject={categoryReading?.reading_value_data}
                              startRange={categoryReading?.minLimit}
                              endRange={categoryReading?.maxLimit}
                              activeRange={categoryReading?.reading_value}
                            />
                          ) : ["bloodpressure"].includes(
                              categoryReading?.keys
                            ) && categoryReading.reading_value_data ? (
                            <>
                              <ProgressResultDetailsCard
                                key={categoryReading?.keys}
                                progressTitle={categoryReading?.reading_name}
                                progressUnit={categoryReading?.unit}
                                vitalBg={categoryReading?.color_code}
                                vitalTextColor="text-color-extra-dark-green"
                                loggedDate={categoryReading?.reading_datetime}
                                info={categoryReading?.information}
                                validation={categoryReading?.validation}
                                readingLimits={categoryReading?.readingLimits}
                                handleOnclick={() =>
                                  handleNextOfSelectedProgress(categoryReading)
                                }
                                valueObject={
                                  categoryReading?.reading_value_data
                                }
                                renderObject={true}
                                startRange={categoryReading?.minLimit}
                                endRange={categoryReading?.maxLimit}
                                activeRange={categoryReading?.reading_value}
                              />
                            </>
                          ) : (
                            <>
                              {categoryReading &&
                              categoryReading?.manualAddFlag === 1 ? (
                                <>
                                  <section className="">
                                    <Card className="p-4 !rounded-3xl flex flex-col gap-4">
                                      <div className="flex justify-between">
                                        <div className="flex items-center justify-center  font-semibold text-base font-poppins text-text-primary">
                                          {categoryReading.reading_name}
                                          <div
                                            className="relative"
                                            ref={iconRef}
                                            onClick={(e) => {
                                              setSelectedVitals(
                                                categoryReading
                                              );
                                              e.stopPropagation();
                                              togglePopup();
                                            }}
                                          >
                                            {categoryReading.information &&
                                              categoryReading.information
                                                .length > 0 && (
                                                <InfoIcon
                                                  className="ml-2"
                                                  color={"gray"}
                                                />
                                              )}
                                          </div>
                                        </div>
                                        <div className="bg-color-light-red text-color-dark-red rounded-full font-poppins text-xs py-1 px-3">
                                          Not Logged
                                        </div>
                                      </div>
                                      <Button
                                        label="Log Your Vital"
                                        type="button"
                                        variant="ternary"
                                        className="w-full"
                                        onClick={() => {
                                          setSelectedVitals(categoryReading);
                                          setLogVitalSheetOpen(true);
                                        }}
                                      >
                                        <RightArrowIcon
                                          color={
                                            theme.colors["--color-dark-blue"]
                                          }
                                        />
                                      </Button>
                                    </Card>
                                  </section>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </React.Fragment>
                      )
                    )
                  ) : (
                    <h1 className="flex justify-center items-center font-poppins text-sm text-text-secodary">
                      Search for Vital Information
                    </h1>
                  )}
                </section>
              </>
            }
          />
        </>
      )}

      {selectedVital && (
        <BottomSheetSelect
          open={logVitalSheetOpen}
          close={() => {
            setLogVitalSheetOpen(false);
          }}
        >
          <LogVitalSheet
            VitalData={selectedVital}
            onClose={() => setLogVitalSheetOpen(false)}
            onSuccess={() => {
              fetchData();
            }}
          />
        </BottomSheetSelect>
      )}

      <BottomSheetSelect open={isOpen} close={() => setIsOpen(false)}>
        <section className="p-4 flex justify-between ">
          <h1 className="text-xl font-bold text-text-secodary">
            {selectedVital?.reading_name}
          </h1>
          <div onClick={() => setIsOpen(false)}>
            <CloseCircleIcon />
          </div>
        </section>
        <hr className="w-full h-[2px] bg-background" />
        <section className="p-4">{selectedVital?.information}</section>
      </BottomSheetSelect>
    </div>
  );
};
