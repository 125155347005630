import React, { FC, useEffect, useRef, useState } from "react";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import Text from "src/components/atoms/Text";
import SyncButton from "src/components/molecules/progress/BCA/SyncButton";
import ScaleImage from "src/assets/images/devices/scale.png";
import RightArrowIcon from "src/assets/icons/progress/blueRightArrow.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getBcaLogsRequest,
  getPatientDetailsForBCARequest,
} from "src/store/slices/bcaSlice";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";
import moment from "moment";
import { toast } from "react-toastify";
import sendMessageToParent from "src/helpers/parentMessageHandler";
import { calculateAge } from "src/helpers/common";

const useDebouncedScroll = (
  callback: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void,
  delay: number
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  return (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(event);
    }, delay);
  };
};

const DeviceLogScreen: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const { data, isLastPageReached, totalLogs, isLoading } = useSelector(
    (state: RootState) => state.bca.BcaLogs
  );
  const { data: patientData, isLoading: loading } = useSelector(
    (state: RootState) => state.bca.PatientData
  );
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (page !== 0) fetchLogs(page);
  }, [page]);

  const fetchLogs = (page: number) => {
    const payload = {
      queryParams: {
        page: page,
        limit: "5",
      },
      body: {
        version: "v1",
      },
    };
    new Promise((resolve, reject) => {
      dispatch(
        getBcaLogsRequest({
          payload,
          resolve,
          reject,
        })
      );
    }).catch((err) => {
      toast.error(err.message);
    });
  };

  useEffect(() => {
    let payload = {};
    new Promise((resolve, reject) => {
      dispatch(
        getPatientDetailsForBCARequest({
          payload,
          resolve,
          reject,
        })
      );
    }).finally(() => {});
  }, []);
  const handleScroll = useDebouncedScroll((event) => {
    const target = event.target as HTMLDivElement;

    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    const scrolledPercentage =
      (scrollTop / (scrollHeight - clientHeight)) * 100;

    if (
      scrolledPercentage >= 70 &&
      !isLoading &&
      !isLastPageReached &&
      data.length < totalLogs
    ) {
      setPage((prev) => prev + 1);
    }
  }, 300); // 300ms debounce delay

  const formatDateAndTime = (dateString: string) => {
    const date = moment(dateString, "YYYY-MM-DD HH:mm:ss").format("DD MMM");
    const time = moment(dateString, "YYYY-MM-DD HH:mm:ss").format("hh:mm A");

    return { date, time };
  };
  const handleConnectDevice = () => {
    if (patientData?.dob && patientData?.gender && patientData?.height) {
      sendMessageToParent("init_device_bottomsheet", {
        age: Number(calculateAge(patientData?.dob, "YYYY-MM-DD")),
        userId: user?.data?.patientID,
        height: Number(patientData?.height),
        gender:
          patientData?.gender === "M" ? 1 : user.platform === "android" ? 2 : 0,
        dob: patientData?.dob,
      });
    } else {
      navigate(`/progress/device-details`);
    }
  };

  return (
    <div className="h-full overflow-scroll" onScroll={handleScroll}>
      <div className="border border-b border-[#E4E7EC]">
        <ProgressBackIcon
          onClick={() => {
            navigate("/progress/progress-device-section");
          }}
        />
      </div>

      {isLoading && loading && page === 1 ? (
        <div className="h-full flex items-center justify-center">
          <SpinnerLoader />
        </div>
      ) : (
        <div>
          <div className="p-4 flex flex-col gap-10 bg-gradient-to-b from-white to-[#FDFBF5]">
            <div>
              <Text variant="headline-large" className="w-full">
                GoodFlip Smart Scale
              </Text>
            </div>
            <div className="w-full flex items-center justify-center">
              <div className="bg-[#00000033] p-4 rounded-xl">
                <img src={ScaleImage} className="rounded-xl h-62 w-60" />
              </div>
            </div>
          </div>
          <div className="relative -top-35.5">
            <div
              className=" h-40 bg-white z-10"
              style={{ clipPath: "ellipse(65% 100px at 50% 100%)" }}
            ></div>
            <div className="flex items-center justify-center top-9 absolute left-[41%] border-white bg-white p-2 rounded-full">
              <SyncButton
                height="h-14"
                width="w-14"
                onClick={() => {
                  handleConnectDevice();
                }}
              />
            </div>
            <div className="p-4 pt-0 relative -top-5">
              {totalLogs === 0 ? (
                <Text
                  variant="title-medium"
                  className="w-full flex items-center justify-center h-50"
                >
                  No logs available
                </Text>
              ) : (
                <>
                  {data.map((log, index) => (
                    <>
                      <div className="flex items-center justify-center gap-3">
                        <div className="w-16 h-[74px] rounded-xl bg-[#FFFBEE] overflow-hidden">
                          <div className="bg-background-light-yellow h-3 "></div>
                          <div className="flex flex-col items-center justify-center pt-2 gap-2">
                            <div className="flex items-center justify-center gap-1">
                              <Text
                                variant="body-large"
                                className="text-text-primary font-bold"
                              >
                                {
                                  formatDateAndTime(
                                    log.created_at ?? ""
                                  ).date.split(" ")[0]
                                }
                              </Text>
                              <Text
                                variant="label-small"
                                className="text-text-primary font-bold"
                              >
                                {
                                  formatDateAndTime(
                                    log.created_at ?? ""
                                  ).date.split(" ")[1]
                                }
                              </Text>
                            </div>
                            <Text
                              variant="label-small"
                              className="font-normal text-text-secodary"
                            >
                              {formatDateAndTime(log.created_at ?? "").time}
                            </Text>
                          </div>
                        </div>
                        <div className="h-16 bg-color-accent-indigo w-[77%] rounded-xl px-4 py-3 flex items-start justify-between">
                          <div className=" flex flex-col">
                            <Text
                              variant="label-large"
                              className="text-[#2D3282] leading-4"
                            >
                              Log #{totalLogs - index}
                            </Text>
                            <div className="flex">
                              <Text
                                variant="label-medium"
                                className="text-text-ternary"
                              >
                                Weight:
                              </Text>
                              <Text
                                variant="label-medium"
                                className="text-text-ternary font-bold pl-1"
                              >
                                {Number(log.weight ?? "0").toFixed(2)} kgs
                              </Text>
                            </div>
                          </div>
                          <div>
                            <img
                              src={RightArrowIcon}
                              onClick={() => {
                                navigate(
                                  `/progress/device-result-page?logId=${log.bca_sync_logs_id}&syncDate=${patientData.last_sync_date}`
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {index !== data.length - 1 && (
                        <div className="h-10 border-l-2 border-dashed  border-[#E7C144] relative left-[8%] w-4 "></div>
                      )}
                    </>
                  ))}
                </>
              )}

              {isLoading && page !== 1 && (
                <div className="mt-5">
                  <SpinnerLoader />
                </div>
              )}
              {/* <div className="h-10 border-l-2 border-dashed  border-[#E7C144] relative left-[8%] "></div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceLogScreen;
