import { FC } from "react";
import Card from "src/components/atoms/Card/Card";

import Text from "src/components/atoms/Text";

import ExclusiveOfferIcon from "src/assets/icons/exclusive_offer.svg";
import BCAIntroDeviceImage from "src/assets/images/BCA/bca_introduction_machine.png";
import Button from "src/components/atoms/Button/Button";
import sendMessageToParent from "src/helpers/parentMessageHandler";

const DevicePurchaseCard: FC = () => {
  const handleBuyNow = () => {
    sendMessageToParent("buy_now", {});
  };

  return (
    <div>
      <Card className="!rounded-4xl">
        <div className="p-3 flex flex-col gap-4">
          <div className="flex gap-2 items-center ">
            <img src={ExclusiveOfferIcon} className="w-10 h-10" />
            <Text variant="title-large" className="text-2xl font-regular">
              Exclusive Offer
            </Text>
          </div>
          <Text variant="title-medium" className="font-normal">
            Unlock Your Path to Better Health Today!
          </Text>
          <div className="flex gap-3">
            <img src={BCAIntroDeviceImage} className="w-26 h-auto" />
            <div className="flex flex-col justify-between">
              <Text
                variant="title-medium"
                className="text-text-ternary font-normal"
              >
                Good Flip Smart Scale
              </Text>
              <div>
                <Text variant="title-small" className="font-light">
                  Limited Time Offer
                </Text>
                <div className="flex gap-1">
                  <Text
                    variant="title-large"
                    className="text-color-extra-light-green "
                  >
                    ₹1,999
                  </Text>

                  <Text
                    variant="title-large"
                    className="font-extralight line-through decoration-1"
                  >
                    ₹2,999
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <Button className="font-semibold" onClick={handleBuyNow}>
            Buy Now
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default DevicePurchaseCard;
