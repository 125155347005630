import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/atoms/Button/Button";

interface Props {
  className: string;
}
const ProgressCard: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  return (
    <div className="mb-[150px] flex-1 border border-[#E4E7EB] bg-white rounded-2xl mx-4 overflow-hidden">
      <img
        src={require("src/assets/images/landing-page/progress.png")}
        alt="Diet/Exercise"
        className="object-contain"
      />
      <div className="px-4 mb-4 mt-[16px]">
        <div className="text-lg font-medium">Review your health journey</div>
        <div className="text-sm text-text-subtitle">
          Track your health vitals, body markers and more
        </div>
        <Button
          variant="primary"
          label="Track your progress"
          className="w-full bg-[#F15223] mt-4"
          onClick={() => navigate("/progress/vitals")}
        />
      </div>
    </div>
  );
};

export default ProgressCard;
