import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPatientDetailsForBCARequest } from "src/store/slices/bcaSlice";
import { RootState } from "src/store";
import Text from "src/components/atoms/Text";
import DevicesCard from "src/components/molecules/progress/BCA/DeviceCard";
import useQueryParams from "src/hooks/useQueryParams";
import ScaleImage from "src/assets/images/devices/scale.png";
import BuyDevices from "../BuyDevices";
import GlucoseImage from "src/assets/images/devices/glucose.png";
import SpirometerImage from "src/assets/images/devices/Spirometer.png";

const Devices = () => {
  const dispatch = useDispatch();
  const { PatientData } = useSelector((state: RootState) => state.bca);
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    let payload = {};
    new Promise((resolve, reject) => {
      dispatch(
        getPatientDetailsForBCARequest({
          payload,
          resolve,
          reject,
        })
      );
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const getDeviceCard = (key: string) => {
    return key === "weigh" ? (
      <div className="bg-[#00000033] px-1 pt-1 w-23 rounded-lg absolute -bottom-1">
        <img src={ScaleImage} className="rounded-md" />
      </div>
    ) : key === "glucose" ? (
      <div className="h-full w-full">
        <img src={GlucoseImage} className="rounded-md" />
      </div>
    ) : key === "spirometer" ? (
      <div className="h-full w-full">
        <img src={SpirometerImage} className="rounded-md" />
      </div>
    ) : (
      <></>
    );
  };
  return (
    <div>
      <Text variant="headline-large">Connected Devices</Text>
      <div className="pt-4 gap-4 flex flex-col  pb-10">
        <div>
          <DevicesCard
            lastSync={PatientData.data.last_sync_date}
            title="Smart Body Composition Analyser"
            device="smart_scale"
            CardImageComponent={getDeviceCard("weigh")}
          />
        </div>
        {/* <div>
          <DevicesCard
            lastSync={moment(new Date().toString()).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
            title="GoodFlip Glucose Meter"
            device="glucose_meter"
            CardImageComponent={getDeviceCard("glucose")}
          />
        </div> */}
        {/* {user.platform === "android" && (
          <div>
            <DevicesCard
              title="Spirometer"
              device="spirometer"
              CardImageComponent={getDeviceCard("spirometer")}
            />
          </div>
        )} */}
      </div>
      <div className="pt-8">
        <BuyDevices />
      </div>
    </div>
  );
};

export default Devices;
