import { put, call, takeEvery, all } from "redux-saga/effects";
import { common } from "../../types";
import * as actions from "../slices/bcaSlice";
import BcaApis from "src/api/bca";
import { constants } from "src/constants/common";

const SetBCADetailsSaga = function* SetBCADetailsSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      BcaApis.setBCAReadings,
      payload
    );
    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }
    yield put(actions.setBcaResultSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.setBcaResultError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const DownloadBCAReportSaga = function* DownloadBCAReportSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      BcaApis.downloadBCAReport,
      payload
    );
    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }
    yield put(actions.downloadBcaReportSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.downloadBcaReportError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const GetPatientDetailsForBCA = function* GetPatientDetailsForBCA({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      BcaApis.getPatientDataForBCA,
      payload
    );
    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }
    yield put(actions.getPatientDetailsForBCASuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getPatientDetailsForBCAError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const GetBcaLogsSaga = function* GetBcaLogsSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      BcaApis.getBcaLogs,
      payload
    );
    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }

    yield put(
      actions.getBcaLogsSuccess({ ...response, page: payload.queryParams.page })
    );
    resolve(response);
  } catch (error) {
    yield put(
      actions.getBcaLogsError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const GetBcaVitalData = function* GetBcaVitalData({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      BcaApis.getBcaVitalData,
      payload
    );
    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }
    yield put(actions.getBcaResultSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getBcaResultError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

function* BcaSaga() {
  yield all([takeEvery(actions.setBcaResultRequest, SetBCADetailsSaga)]);
  yield all([
    takeEvery(actions.downloadBcaReportRequest, DownloadBCAReportSaga),
  ]);
  yield all([
    takeEvery(actions.getPatientDetailsForBCARequest, GetPatientDetailsForBCA),
  ]);
  yield all([takeEvery(actions.getBcaLogsRequest, GetBcaLogsSaga)]);
  yield all([takeEvery(actions.getBcaResultRequest, GetBcaVitalData)]);
}

export default BcaSaga;
