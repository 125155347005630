function bmiRanges() {
  return [
    {
      from: 0,
      label: "Low",
      to: 18.5,
    },
    {
      from: 18.5,
      label: "Normal",
      to: 25,
    },
    {
      from: 25,
      label: "High",
      to: 30,
    },
    {
      from: 30,
      label: "Too High",
      to: 168,
    },
  ];
}

function muscleRateRanges(isMale: boolean) {
  return [
    {
      from: 0,
      label: "Low",
      to: isMale ? 40 : 30,
    },
    {
      from: isMale ? 40 : 30,
      label: "Ideal",
      to: isMale ? 60 : 50,
    },
    {
      from: isMale ? 60 : 50,
      label: "Excellent",
      to: 100,
    },
  ];
}

function bodyMoistureRanges(isMale: boolean) {
  return [
    {
      from: 0,
      label: "Low",
      to: isMale ? 55 : 45,
    },
    {
      from: isMale ? 55 : 45,
      label: "Normal",
      to: isMale ? 65 : 60,
    },
    {
      from: isMale ? 65 : 60,
      label: "High",
      to: 100,
    },
  ];
}

function visceralFatIndexRanges() {
  return [
    {
      from: 0,
      label: "Normal",
      to: 9,
    },
    {
      from: 9,
      label: "High",
      to: 14,
    },
    {
      from: 14,
      label: "Too High",
      to: 30,
    },
  ];
}

function subcutaneousFatRanges(isMale: boolean) {
  return [
    {
      from: 0,
      label: "Low",
      to: isMale ? 7 : 11,
    },
    {
      from: isMale ? 7 : 11,
      label: "Normal",
      to: isMale ? 15 : 17,
    },
    {
      from: isMale ? 15 : 17,
      label: "High",
      to: isMale ? 30 : 40,
    },
  ];
}

function proteinRateRanges(isMale: boolean) {
  return [
    {
      from: 0,
      label: "Low",
      to: isMale ? 16 : 14,
    },
    {
      from: isMale ? 16 : 14,
      label: "Ideal",
      to: isMale ? 18 : 16,
    },
    {
      from: isMale ? 18 : 16,
      label: "Excellent",
      to: 30,
    },
  ];
}

function muscleMassRanges(isMale: boolean) {
  return [
    {
      from: 0,
      label: "Low",
      to: isMale ? 40 : 30,
    },
    {
      from: isMale ? 40 : 30,
      label: "Normal",
      to: isMale ? 60 : 50,
    },
    {
      from: isMale ? 60 : 50,
      label: "Good",
      to: 100,
    },
  ];
}

function proteinAmountRanges(isMale: boolean) {
  return [
    {
      from: 0,
      label: "Low",
      to: isMale ? 16 : 14,
    },
    {
      from: isMale ? 16 : 14,
      label: "Normal",
      to: isMale ? 18 : 16,
    },
    {
      from: isMale ? 18 : 16,
      label: "Good",
      to: 30,
    },
  ];
}

function obesityGradeRanges() {
  return [
    {
      from: -0.5,
      label: "Low",
      to: -0.2,
    },
    {
      from: -0.2,
      label: "Thin",
      to: -0.1,
    },
    {
      from: -0.1,
      label: "Ideal",
      to: 0.1,
    },
    {
      from: 0.1,
      label: "Partiallity",
      to: 0.2,
    },
    {
      from: 0.2,
      label: "Over weight",
      to: 0.5,
    },
  ];
}

function heightRanges(height: number) {
  const heightInM = Number((Number(height) / 100).toFixed(2));
  return [
    {
      from: 0,
      label: "Low",
      to: 18.5 * Math.pow(heightInM, 2),
    },
    {
      from: 18.5 * Math.pow(heightInM, 2),
      label: "Normal",
      to: 25 * Math.pow(heightInM, 2),
    },
    {
      from: 25 * Math.pow(heightInM, 2),
      label: "High",
      to: 30 * Math.pow(heightInM, 2),
    },
    {
      from: 30 * Math.pow(heightInM, 2),
      label: "Too High",
      to: 700,
    },
  ];
}

function fatRanges(weight: number, isMale: boolean) {
  return isMale
    ? [
        { from: 0, to: Number((0.14 * weight).toFixed(2)), label: "Low" },
        {
          from: Number((0.14 * weight).toFixed(2)),
          to: Number((0.24 * weight).toFixed(2)),
          label: "Normal",
        },
        {
          from: Number((0.24 * weight).toFixed(2)),
          to: Number((0.5 * weight).toFixed(2)),
          label: "High",
        },
      ]
    : [
        { from: 0, to: Number((0.21 * weight).toFixed(2)), label: "Low" },
        {
          from: Number((0.21 * weight).toFixed(2)),
          to: Number((0.31 * weight).toFixed(2)),
          label: "Normal",
        },
        {
          from: Number((0.31 * weight).toFixed(2)),
          to: Number((0.5 * weight).toFixed(2)),
          label: "High",
        },
      ];
}

function boneMassRanges(isMale: boolean) {
  return isMale
    ? [
        { from: 0, to: 2.5, label: "Low" },
        { from: 2.5, to: 3.2, label: "Normal" },
        { from: 3.2, to: 4.5, label: "Good" },
      ]
    : [
        { from: 0, to: 1.8, label: "Low" },
        { from: 1.8, to: 2.5, label: "Normal" },
        { from: 2.5, to: 3.5, label: "Good" },
      ];
}

function metabolicAgeRanges(actualAge: number) {
  return [
    { from: 0, to: actualAge, label: "Normal" },
    { from: actualAge + 1, to: actualAge + 10, label: "High" },
  ];
}

function skeletalMuscleRanges(isMale: boolean) {
  return isMale
    ? [
        { from: 0, to: 30, label: "Low" },
        { from: 30, to: 35, label: "Normal" },
        { from: 35, to: 40, label: "High" },
        { from: 40, to: 45, label: "Too High" },
      ]
    : [
        { from: 0, to: 25, label: "Low" },
        { from: 25, to: 30, label: "Normal" },
        { from: 30, to: 35, label: "High" },
        { from: 35, to: 40, label: "Too High" },
      ];
}

export function bmrRange(isMale: boolean) {
  return isMale
    ? [
        { from: 0, index: 0, label: "Low", to: 1600 },
        { from: 1601, index: 1, label: "Normal", to: 1800 },
        { from: 1801, index: 2, label: "High", to: 2499 },
      ]
    : [
        { from: 0, index: 0, label: "Low", to: 1200 },
        { from: 1201, index: 1, label: "Normal", to: 1500 },
        { from: 1501, index: 2, label: "High", to: 2499 },
      ];
}

function trackyVitalsRange(vitals: any) {
  const { isMale, height, weight, age } = vitals;
  return {
    bmi: bmiRanges(),
    bmr: bmrRange(isMale),
    muscle_rate: muscleRateRanges(isMale),
    hydration: bodyMoistureRanges(isMale),
    visceral_fat: visceralFatIndexRanges(),
    subcutaneous_fat: subcutaneousFatRanges(isMale),
    protein_rate: proteinRateRanges(isMale),

    muscle_mass: muscleMassRanges(isMale),
    protein: proteinAmountRanges(isMale),
    obesity_grade: obesityGradeRanges(),
    weight: heightRanges(height),

    fat: fatRanges(weight, isMale),
    bone_mass: boneMassRanges(isMale),
    metabolic_age: metabolicAgeRanges(age),
    skeletal_muscle: skeletalMuscleRanges(isMale),

    // body_fate_rate // double condition
    // bone mass // double condition
    // basal metabolic rate // double condition
    // body age  // double condition
    // standard weight // double condition
    // wight control // not have range
    // fat mass // double condition
    // lean body mass // double condition
    // body type // double condition
    // body score // not have range

    // static
    // fat_mass: [
    //   {
    //     to: 10,
    //     from: 0,
    //     label: 'Low',
    //   },
    //   {
    //     to: 22,
    //     from: 11,
    //     label: 'Normal',
    //   },
    //   {
    //     to: 27,
    //     from: 23,
    //     label: 'High',
    //   },
    //   {
    //     to: 50,
    //     from: 28,
    //     label: 'Too High',
    //   },
    // ],
    // bone_mass: [
    //   {
    //     to: 2.6,
    //     from: 0,
    //     label: 'Low',
    //   },
    //   {
    //     to: 3.7,
    //     from: 2.7,
    //     label: 'Normal',
    //   },
    //   {
    //     to: 5,
    //     from: 3.8,
    //     label: 'Good',
    //   },
    // ],
    // metabolic_age: [
    //   {
    //     to: 41,
    //     from: 31,
    //     label: 'Normal',
    //   },
    //   {
    //     to: 51,
    //     from: 42,
    //     label: 'High',
    //   },
    // ],
    // skeletal_muscle: [
    //   {
    //     to: 33.1,
    //     from: 0,
    //     label: 'Low',
    //   },
    //   {
    //     to: 39.1,
    //     from: 33.2,
    //     label: 'Normal',
    //   },
    //   {
    //     to: 43.8,
    //     from: 39.2,
    //     label: 'High',
    //   },
    //   {
    //     to: 50,
    //     from: 43.9,
    //     label: 'Too High',
    //   },
    // ],
  };
}

export { trackyVitalsRange };
