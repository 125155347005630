import React, { FC, useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";

import Text from "src/components/atoms/Text";
import KeyBCAParameters from "src/components/organisms/Progress/BCA/KeyBcaParameters";

import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import BCAIntroductionJson from "src/assets/animations/bca/bca_introduction.json";
import BlackCircleIMG from "src/assets/images/circles_black.png";
import BCAIntroDeviceImg from "src/assets/images/BCA/bca_introduction_machine.png";
import DevicePurchaseCard from "src/components/organisms/Progress/BCA/DevicePurchaseCard";
import DeviceAdvantageImage from "src/assets/images/BCA/bca_advantages.png";
import BCAFooterImage from "src/assets/images/BCA/bca_footer.png";
import Button from "src/components/atoms/Button/Button";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import sendMessageToParent from "src/helpers/parentMessageHandler";
import { calculateAge } from "src/helpers/common";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPatientDetailsForBCARequest } from "src/store/slices/bcaSlice";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";

const DeviceDetailsScreen: FC = () => {
  const targetRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { PatientData } = useSelector((state: RootState) => state.bca);
  const [showBottomTab, setShowBottomTab] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setShowBottomTab(!entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let payload = {};
    new Promise((resolve, reject) => {
      dispatch(
        getPatientDetailsForBCARequest({
          payload,
          resolve,
          reject,
        })
      );
    }).finally(() => {
      setLoading(false);
    });
  }, []);
  const handleConnectDevice = () => {
    if (
      PatientData.data?.dob &&
      PatientData.data?.gender &&
      PatientData.data?.height
    ) {
      sendMessageToParent("init_device_bottomsheet", {
        age: Number(calculateAge(PatientData.data.dob, "YYYY-MM-DD")),
        userId: user.data.patientID,
        height: Number(PatientData.data.height),
        gender:
          PatientData.data.gender === "M"
            ? 1
            : user.platform === "android"
            ? 2
            : 0,
        dob: PatientData.data?.dob,
      });
    } else {
      navigate(`/progress/device-provide-details?device=Good Flip`);
    }
  };

  return (
    <div className="relative">
      {/* Header */}
      <div className="border border-b border-[#E4E7EC]">
        <ProgressBackIcon
          onClick={() => {
            navigate("/progress/progress-device-section");
          }}
        />
      </div>
      {loading ? (
        <div>
          <SpinnerLoader />
        </div>
      ) : (
        <>
          {/* Main Section */}
          <div
            className="bg-color-extra-dark-gray  rounded-bl-4xl"
            ref={targetRef}
          >
            <div className="w-full relative flex flex-col items-center justify-center text-white">
              <img
                src={BlackCircleIMG}
                className="w-full h-auto absolute top-14 left-0"
              />
              <div>
                <Lottie animationData={BCAIntroductionJson} loop />
              </div>
              <div>
                <img
                  src={BCAIntroDeviceImg}
                  className="w-39 h-auto absolute left-[30%] top-26"
                />
              </div>
              <div className="p-6 pt-0 pb-6">
                <Text className="font-medium text-2xl leading-7">
                  {" "}
                  Know more than just your weight!
                  <span className="text-color-light-yellow">
                    {" "}
                    Improve your health with data
                  </span>
                </Text>
                <div className=" py-2">
                  By syncing your scale, you'll gain valuable information to
                  fine-tune your wellness routine and stay motivated
                </div>
                {/* <div
              className="w-[94%] bg-primary rounded-xl text-white p-2 text-center mt-2"
              onClick={() => {}}
            > */}
                <Button
                  className="mb-2 h-12 w-full !rounded-xl mt-4 z-10"
                  onClick={() => {
                    handleConnectDevice();
                  }}
                >
                  Connect With Smart Scale
                </Button>
                {/* </div> */}
              </div>
            </div>
          </div>

          {/* Purchase scale */}
          <div className="p-4">
            <div className="flex flex-col gap-3">
              <Text variant="title-medium" className="font-regular">
                Don't have smart scale? Try the new Good Flip Smart Scale
              </Text>
              <DevicePurchaseCard />
            </div>
          </div>

          {/* Key Parameters */}
          <KeyBCAParameters />

          <div className="mt-22 flex flex-col bg-gradient-to-b from-white to-color-darker-lavender overflow-hidden pt-4">
            {/* Introduction */}
            <div>
              <div className="flex relative w-full">
                <img
                  src={BCAIntroDeviceImg}
                  className="absolute rotate-12 w-62 -left-[27%] h-auto"
                />
                <div className="pl-44 pt-8 flex flex-col gap-2">
                  <Text
                    variant="headline-large"
                    className="font-roboto font-normal"
                  >
                    Introducing MyTatva Smart Scale
                  </Text>
                  <Text
                    variant="title-large"
                    className="text-text-secodary font-light"
                  >
                    The most advanced body composition analyser
                  </Text>
                </div>
              </div>
            </div>

            {/* features */}
            <div className="mt-28 flex flex-col gap-3">
              <div className="flex flex-col gap-3 p-4">
                <Text
                  variant="headline-medium"
                  className="font-roboto font-normal"
                >
                  {" "}
                  Why is MyTatva Smart Scale the key to your health?
                </Text>
                <Text
                  variant="title-medium"
                  className="font-light text-text-ternary"
                >
                  {" "}
                  Here are some benefits you can expect from our
                  state-of-the-art device:
                </Text>
              </div>
              <div>
                <img src={DeviceAdvantageImage} className="h-auto w-full" />
              </div>
            </div>

            {/* Buy Device card */}
            <div className="p-4 mt-12">
              <DevicePurchaseCard />
            </div>

            <div className="mt-12">
              <img src={BCAFooterImage} className="w-full h-auto" />
            </div>
          </div>
        </>
      )}

      {showBottomTab && (
        <div
          className={classNames(
            `p-4 bg-white sticky  bottom-0 w-full border-t-2  transform transition-all duration-400 ease-out animate-slide-up`
          )}
        >
          <Button
            label="Connect With Smart Scale"
            onClick={handleConnectDevice}
            className="mb-2 h-12 w-full !rounded-xl"
          />
        </div>
      )}
    </div>
  );
};

export default DeviceDetailsScreen;
