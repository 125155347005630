import React, { FC } from "react";

interface Props {
  color?: string;
  filled?: boolean;
}

const ViewMoreIcon: FC<Props> = ({ color = "#4B4AD5", filled = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="12" fill={color} />
      <path
        d="M13.6201 7.95337L17.6668 12L13.6201 16.0467"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.33325 12H17.5533"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ViewMoreIcon;
