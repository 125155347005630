import React, { FC, ReactNode } from "react";
import Text from "src/components/atoms/Text";
import { BcaParameterValueType } from "src/types/bca";

interface BcaVitalInfoPropsType {
  ReadingCard: ReactNode;
  parameter: BcaParameterValueType;
}
const BcaVitalInfo: FC<BcaVitalInfoPropsType> = ({
  ReadingCard,
  parameter,
}) => {
  return (
    <div className="p-5 pb-0">
      <Text variant="label-large" className="font-manrope text-xl font-medium">
        {" "}
        More Information{" "}
      </Text>
      <div className="mt-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <div className="bg-background-fourth w-8 h-8 rounded-full">
              <img src={parameter.icon} />
            </div>
            <div className="font-manrope text-base font-bold leading-5 text-text-primary">
              {parameter.name}
            </div>
          </div>
          <div className="flex text-text-ternary">
            <Text variant="caption1-regular">Unit:</Text>
            <Text variant="caption1-regular" className="font-bold ml-1">
              {parameter.unit}
            </Text>
          </div>
        </div>
        <div className="text-text-ternary mt-4">{parameter.description}</div>
        <div className="mt-10">{ReadingCard}</div>
        <div className="mt-6">
          <img src={parameter.image} />
        </div>
      </div>
    </div>
  );
};

export default BcaVitalInfo;
