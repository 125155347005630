import classNames from "classnames";
import React from "react";

interface TextInputAnimatedProps {
  id: string;
  label: string;
  type?: string;
  value: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  required?: boolean;
  readonly?: boolean;
  handleOnClick?: () => void;
  width?: string;
  height?: string;
  placeholder?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  unit?: string;
}

const TextInputAnimated: React.FC<TextInputAnimatedProps> = ({
  id,
  label,
  value,
  type = "text",
  onChange = () => {},
  className = "",
  required = false,
  readonly = false,
  handleOnClick,
  width = "100%",
  height = "auto",
  placeholder = "",
  onKeyDown,
  onFocus,
  unit,
}) => {
  return (
    <div
      className={classNames(
        "relative w-full select-none shadow-lg flex ",
        className,
        unit ? "border-black rounded-2xl border flex justify-between" : ""
      )}
      style={{ width }}
    >
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder || " "}
        required={required}
        readOnly={readonly}
        onKeyDown={onKeyDown}
        onClick={handleOnClick}
        className={classNames(
          "block text-text-primary select-none px-3.5 pb-2.5 pt-5 text-sm font-medium focus:ring-0 rounded-2xl peer",
          unit ? "w-[inherit]" : "w-full  border-black rounded-2xl border"
        )}
        style={{ height }}
        onFocus={onFocus}
        tabIndex={-1}
      />
      {unit && (
        <span className="float-end text-color-light-gray bg-color-extra-light-gray p-3 px-4 flex items-center justify-center rounded-r-2xl border border-l-color-light-gray">
          {unit}
        </span>
      )}
      <label
        htmlFor={id}
        className="absolute select-none text-sm text-text-ternary dark:text-text-subtitle duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-3.5 peer-focus:text-text-subtitle peer-focus:dark:text-text-subtitle peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
        {label}
      </label>
    </div>
  );
};

export default TextInputAnimated;
