import BCABMI from "src/assets/images/BCA/bca_bmi.svg";
import BCABMR from "src/assets/images/BCA/bca_bmr.svg";
import BCABodyFat from "src/assets/images/BCA/bca_body_fat.svg";
import BCABodyWeight from "src/assets/images/BCA/bca_body_weight.svg";
import BCABoneMass from "src/assets/images/BCA/bca_bone_mass.svg";
import BCAHydration from "src/assets/images/BCA/bca_hydration.svg";
import BCAMetabolicAge from "src/assets/images/BCA/bca_metabolic_age.svg";
import BCAMuscleMass from "src/assets/images/BCA/bca_muscle_mass.svg";
import BCAProtein from "src/assets/images/BCA/bca_protein.svg";
import BCASkeletalMuscle from "src/assets/images/BCA/bca_skeletal_mass.svg";
import BCASubcutaneousFat from "src/assets/images/BCA/bca_subcutaneous_fat.svg";
import BCAVisceralFat from "src/assets/images/BCA/bca_visceral_fat.svg";

import BMIImage from "src/assets/images/BCA/bca_bmi.png";
import BMRImage from "src/assets/images/BCA/bca_bmr.png";
import BodyFatImage from "src/assets/images/BCA/bca_body_fat.png";
import BoneMassImage from "src/assets/images/BCA/bca_bone_mass.png";
import MuscleMassImage from "src/assets/images/BCA/bca_muscle_mass.png";
import HydrationImage from "src/assets/images/BCA/bca_hydration.png";
import MetabolicAgeImage from "src/assets/images/BCA/bca_metalbolic_age.png";
import ProteinImage from "src/assets/images/BCA/bca_protein.png";
import SkeletalMassImage from "src/assets/images/BCA/bca_skeletal_mass.png";
import SubcutaneousImage from "src/assets/images/BCA/bca_subcutaneous_fat.png";
import VisceralFatImage from "src/assets/images/BCA/bca_visceral_fat.png";
import BodyWeightImage from "src/assets/images/BCA/bca_body_weight.png";
import { BcaKeyParameterType } from "src/types/bca";

export const BCA_KEY_PARAMETERS: BcaKeyParameterType = {
  bone_mass: {
    id: "bone_mass",
    description:
      "Delve into your bone mass measurements to fortify your skeletal health and resilience against fractures and osteoporosis",
    icon: BCABoneMass,
    image: BoneMassImage,
    name: "Bone Mass",
    unit: "Kg",
  },
  fat_mass: {
    id: "fat_mass",
    description:
      "Delve into your bone mass measurements to fortify your skeletal health and resilience against fractures and osteoporosis",
    icon: BCABodyFat,
    image: BodyFatImage,
    name: "Fat",
    unit: "Kg",
  },
  BMI: {
    id: "BMI",
    description:
      "Find balance, find health. Gauge your BMI to understand your weight status and set achievable health goals",
    icon: BCABMI,
    image: BMIImage,
    name: "BMI",
    unit: "Kg/m²",
  },
  BMR: {
    id: "BMR",
    description:
      "Unlock the key to sustainable weight management. Calculate your BMR to tailor your calorie intake for lasting results",
    icon: BCABMR,
    image: BMRImage,
    name: "BMR",
    unit: "Kcal",
  },

  weight: {
    id: "weight",
    name: "Body Weight",
    unit: "Kg",
    image: BodyWeightImage,
    description:
      "Tracking your body weight regularly helps you understand how your lifestyle, diet, and physical activities affect your overall health",
    icon: BCABodyWeight,
  },

  hydration: {
    id: "hydration",
    description:
      "Hydration refers to the body's state of having an adequate amount of water to function optimally",
    icon: BCAHydration,
    image: HydrationImage,
    name: "Hydration",
    unit: "%",
  },
  metabolic_age: {
    id: "metabolic_age",
    description:
      "Ignite your metabolism and fuel your fitness journey. Track your metabolic rate to fine-tune your nutrition and exercise",
    icon: BCAMetabolicAge,
    image: MetabolicAgeImage,
    name: "Metabolic Age",
    unit: "Years",
  },
  muscle_mass: {
    id: "muscle_mass",
    description:
      "Discover the strength within you! Measure your muscle mass to optimize your fitness routine and muscle-building efforts",
    icon: BCAMuscleMass,
    image: MuscleMassImage,
    name: "Muscle Mass",
    unit: "Kg",
  },
  protein: {
    id: "protein",
    description:
      "Discover the protein power within, essential for muscle repair, growth, and overall well-being",
    icon: BCAProtein,
    image: ProteinImage,
    name: "Protein",
    // TODO: no unit for protien
    unit: "%",
  },
  // protein_rate: {
  //   id: "protein_rate",
  //   description:
  //     "Discover the protein power within, essential for muscle repair, growth, and overall well-being",
  //   icon: BCAProtein,
  //   name: "Protein Rate",
  //   unit: "%",
  // },
  skeletal_muscle_kg: {
    id: "skeletal_muscle_kg",
    description:
      "Go beyond the surface. Measure your visceral fat to assess your risk of chronic diseases and prioritize your health",
    icon: BCASkeletalMuscle,
    image: SkeletalMassImage,
    name: "Skeletal Muscle",
    unit: "kg",
  },
  subcutaneous_fat_percent: {
    id: "subcutaneous_fat_percent",
    description:
      "Unveil the hidden layer that wraps around your body, protecting and shaping your form with style.",
    icon: BCASubcutaneousFat,
    image: SubcutaneousImage,
    name: "Subcutaneous Fat",
    unit: "%",
  },
  visceral_fat: {
    id: "visceral_fat",
    description:
      "Go beyond the surface. Measure your visceral fat to assess your risk of chronic diseases and prioritize your health",
    icon: BCAVisceralFat,
    image: VisceralFatImage,
    name: "Visceral Fat",
    unit: "%",
  },
};

export const GoodFlipScaleRange: any = {
  BMI: [
    {
      to: 18.4,
      from: 0,
      label: "Low",
    },
    {
      to: 24.9,
      from: 18.5,
      label: "Normal",
    },
    {
      to: 29.9,
      from: 25,
      label: "High",
    },
    {
      to: 50,
      from: 30,
      label: "Too High",
    },
  ],
  fat_mass: [
    {
      to: 10,
      from: 0,
      label: "Low",
    },
    {
      to: 22,
      from: 11,
      label: "Normal",
    },
    {
      to: 27,
      from: 23,
      label: "High",
    },
    {
      to: 50,
      from: 28,
      label: "Too High",
    },
  ],
  weight: [
    {
      to: 53.17599999999999,
      from: 26.01,
      label: "Low",
    },
    {
      to: 71.961,
      from: 53.465,
      label: "Normal",
    },
    {
      to: 86.411,
      from: 72.25,
      label: "High",
    },
    {
      to: 121.38000000000002,
      from: 86.7,
      label: "Too High",
    },
  ],
  protein: [
    {
      to: 15.9,
      from: 0,
      label: "Low",
    },
    {
      to: 18,
      from: 16,
      label: "Normal",
    },
    {
      to: 25,
      from: 18.1,
      label: "Good",
    },
  ],
  bone_mass: [
    {
      to: 2.6,
      from: 0,
      label: "Low",
    },
    {
      to: 3.7,
      from: 2.7,
      label: "Normal",
    },
    {
      to: 5,
      from: 3.8,
      label: "Good",
    },
  ],
  hydration: [
    {
      to: 49.9,
      from: 0,
      label: "Low",
    },
    {
      to: 65,
      from: 50,
      label: "Normal",
    },
    {
      to: 100,
      from: 65.1,
      label: "High",
    },
  ],
  muscle_mass: [
    {
      to: 43.9,
      from: 0,
      label: "Low",
    },
    {
      to: 52.4,
      from: 44,
      label: "Normal",
    },
    {
      to: 100,
      from: 52.5,
      label: "Good",
    },
  ],
  visceral_fat: [
    {
      to: 10,
      from: 0,
      label: "Normal",
    },
    {
      to: 15,
      from: 11,
      label: "High",
    },
    {
      to: 25,
      from: 16,
      label: "Too High",
    },
  ],
  metabolic_age: [
    {
      to: 41,
      from: 31,
      label: "Normal",
    },
    {
      to: 51,
      from: 42,
      label: "High",
    },
  ],
  skeletal_muscle: [
    {
      to: 33.1,
      from: 0,
      label: "Low",
    },
    {
      to: 39.1,
      from: 33.2,
      label: "Normal",
    },
    {
      to: 43.8,
      from: 39.2,
      label: "High",
    },
    {
      to: 50,
      from: 43.9,
      label: "Too High",
    },
  ],
  subcutaneous_fat: [
    {
      to: 8.5,
      from: 0,
      label: "Low",
    },
    {
      to: 16.7,
      from: 8.6,
      label: "Normal",
    },
    {
      to: 20.7,
      from: 16.8,
      label: "High",
    },
    {
      to: 30,
      from: 20.8,
      label: "Too High",
    },
  ],
};
