import { bindQueryParams } from "src/helpers/common";
import { methods, request } from "./base";

const BcaApis = {
  setBCAReadings: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/update_bca_readings",
      priv: true,
      payload,
    }),
  downloadBCAReport: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/generate_bca_report",
      priv: true,
      payload,
    }),
  getPatientDataForBCA: async (payload: object) =>
    await request({
      method: methods.GET,
      route: "/patient/get_patient_bca_last_sync_date",
      priv: true,
    }),
  getBcaVitalData: async (payload: any) => {
    const route = bindQueryParams(
      "/patient/get_patient_bca_info_by_id",
      payload?.queryParams
    );

    return request({
      method: methods.GET,
      route,
      priv: true,
    });
  },

  getBcaLogs: (payload: any) => {
    const route = bindQueryParams(
      "/patient/get_patient_bca_logs",
      payload?.queryParams
    );
    return request({
      method: methods.GET,
      route,
      priv: true,
    });
  },
};

export default BcaApis;
