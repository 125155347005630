import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import useQueryParams from "src/hooks/useQueryParams";
import { setPlatform } from "src/store/slices/authSlice";

const BCAHoc = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const ComponentWithLogging: React.FC<P> = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = useQueryParams(location.search);

    useEffect(() => {
      if (queryParams["platform"]) {
        dispatch(setPlatform(queryParams["platform"]));
      }
    }, []);

    return <WrappedComponent {...props} />;
  };

  return ComponentWithLogging;
};

export default BCAHoc;
