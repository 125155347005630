import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useQueryParams from "src/hooks/useQueryParams";

import Card from "src/components/atoms/Card/Card";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import BarChart from "src/components/molecules/chart/Chart";
import { Header } from "src/components/molecules/Header";
import DayMonthHeader from "src/components/molecules/Insights/DayMonthHeader";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import ExerciseGoalSelectionBottomSheet from "src/components/molecules/Insights/ExerciseGoalSelectionBottomSheet";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";

import { insightsFilterOptions } from "src/constants/insights";
import { RootState } from "src/store";
import { fetchGraphRecordsRequest } from "src/store/slices/exerciseSlice";
import { constants } from "src/constants/common";

const ExerciseInsightsPage: React.FC = () => {
  const [selectedDateOption, setSelectedDateOption] = useState<string>(
    insightsFilterOptions[0].value
  );
  const [selectedGoal, setSelectedGoal] = useState<any>(0);
  const [showGoalBottomSheet, setShowGaolBottomSheet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = useQueryParams();
  const [searchParams] = useSearchParams();
  const goalId = searchParams.get("goal_id");

  const { user } = useSelector((state: RootState) => state.auth);
  const { graphDetails } = useSelector((state: RootState) => state.exercise);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const date = queryParams?.date || moment(new Date()).format("YYYY-MM-DD");
  const isPaidUser = !!user.data.paidUser;
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    fetchData(selectedDateOption);
  }, [selectedDateOption]);

  const fetchData = (selectedDateOption: any) => {
    setIsLoading(true);
    const payload = {
      goal_id: goalId,
      goal_time: selectedDateOption,
      date: moment(date ?? new Date()).format("YYYY-MM-DD"),
    };
    new Promise((resolve, reject) => {
      dispatch(fetchGraphRecordsRequest({ payload, resolve, reject }));
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (graphDetails) {
      setSelectedGoal(graphDetails?.data?.goal_value);
    }
  }, [graphDetails]);

  const getDateRange = () => {
    const selectedDate = date ?? new Date();
    switch (selectedDateOption) {
      case "1D":
        return moment(selectedDate).diff(moment(), "days") === 0
          ? "Today"
          : moment(selectedDate).format("DD MMM YYYY");
      case "7D":
        return `${moment(selectedDate)
          .subtract(6, "days")
          .format("DD MMM")} - ${moment(selectedDate).format("DD MMM")}`;
      case "1M":
        // case '30D':
        return `${moment(selectedDate)
          .subtract(1, "months")
          .format("DD MMM")} - ${moment(selectedDate).format("DD MMM YYYY")}`;
      case "6M":
        return `${moment(selectedDate)
          .subtract(6, "months")
          .format("DD MMM")} - ${moment(selectedDate).format("DD MMM YYYY")}`;
      case "1Y":
        return `${moment(selectedDate)
          .subtract(11, "months")
          .format("MMM YYYY")} - ${moment(selectedDate).format("MMM YYYY")}`;
    }
  };

  const goalValue =
    (selectedDateOption === "1D"
      ? graphDetails.data?.todays_achieved_value
      : graphDetails?.data?.average?.goal_value) ?? 0;

  return (
    <div className="bg-background">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div className="">
            <Header
              title="Exercise Insights"
              onClickOfBackBtn={() =>
                navigate(`/exercise?goal_id=${goalId}&selectedDate=${date}`)
              }
            />
            <DayMonthHeader
              selectedDateOption={selectedDateOption}
              setSelectedDateOption={(item) => setSelectedDateOption(item)}
            />
          </div>
        }
        component2={
          isLoading ? (
            <div className="h-full flex items-center justify-center">
              <SpinnerLoader />
            </div>
          ) : graphDetails?.data ? (
            <div className="px-4">
              <div>
                <p>
                  {goalValue >= 0 && (
                    <>
                      <span className="text-sm text-text-subtitle">
                        {selectedDateOption === "1D" ? "Total" : "Average"}
                      </span>
                      <br />
                      <span className="text-2xl font-semibold text-black">
                        {goalValue > 0
                          ? Number(goalValue).toFixed(2)
                          : goalValue}
                      </span>{" "}
                      <span className="text-sm text-text-subtitle">min</span>
                      <br />
                    </>
                  )}
                  <span className="text-sm text-text-subtitle">
                    {getDateRange()}
                  </span>
                </p>
              </div>

              <BarChart
                className="mt-4"
                data={{
                  x: graphDetails?.data?.goal_data.map(
                    (goal: any) => goal.x_value
                  ),
                  y: graphDetails?.data?.goal_data?.map(
                    (goal: any) => goal?.achieved_value
                  ),
                }}
                goalValue={selectedGoal}
              />

              <Card className="mt-4 !p-0">
                <button
                  className="p-4 w-full flex items-center justify-between"
                  disabled={isPaidUser}
                  onClick={() => setShowGaolBottomSheet(true)}
                >
                  <p className="text-sm font-semibold text-text-title">Goal</p>
                  <p className="text-text-subtitle">
                    {isNaN(state?.exerciseDuration)
                      ? 0
                      : Math.round(
                          state?.exerciseDuration
                            ? state?.exerciseDuration
                            : selectedGoal
                        )}
                    {` min `}
                    {!isPaidUser ? (
                      <i className="fa fa-solid fa-chevron-right pl-2 text-md" />
                    ) : null}
                  </p>
                </button>
              </Card>

              <section className="mb-4">
                <h3 className="text-base text-text-title font-semibold py-4">
                  About Exercise
                </h3>
                <Card className="p-4">
                  <p className="text-text-subtitle text-sm">
                    {graphDetails.data?.about_us ||
                      "Exercise strengthens muscles and the cardiovascular system, which is crucial for health and disease prevention. Make better lifestyle choices by exercising regularly, thereby paving the way to a healthier life!"}
                  </p>
                </Card>
              </section>
            </div>
          ) : graphDetails?.error ? (
            <div className="h-full flex items-center justify-center">
              <p className="text-gray-500">
                {constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG}
              </p>
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              <SpinnerLoader />
            </div>
          )
        }
      />

      <BottomSheetSelect
        open={showGoalBottomSheet}
        close={() => setShowGaolBottomSheet(false)}
      >
        <ExerciseGoalSelectionBottomSheet
          selectedGoal={String(Number(selectedGoal).toFixed(0))}
          closeBottomSheet={() => {
            setShowGaolBottomSheet(false);
            fetchData(selectedDateOption);
          }}
        />
      </BottomSheetSelect>
    </div>
  );
};

export default ExerciseInsightsPage;
