import React, { FC } from "react";
import Card from "src/components/atoms/Card/Card";
import Text from "src/components/atoms/Text";

interface BCAVitalDescriptionCardPropsType {
  VitalData: any;
}

const BCAVitalDescriptionCard: FC<BCAVitalDescriptionCardPropsType> = ({
  VitalData,
}) => {
  return (
    <div>
      <Card className="!bg-color-lavender !p-0 flex flex-col justify-between !rounded-4xl">
        <div className="flex flex-col gap-4 p-8 pb-0 ">
          <div className="flex gap-2 w-80 items-center ">
            <div className="bg-background-fourth w-11 h-11 rounded-full flex items-center justify-center">
              <img src={VitalData.icon} />
            </div>
            <Text variant="headline-large" className="font-normal font-roboto">
              {VitalData.name}
            </Text>
          </div>
          <div>
            <Text
              variant="title-medium"
              className="font-light text-text-secodary h-36"
            >
              {VitalData.description}
            </Text>
          </div>
        </div>
        <div className="w-full">
          <img src={VitalData.image} className="w-full h-auto" />
        </div>
      </Card>
    </div>
  );
};

export default BCAVitalDescriptionCard;
