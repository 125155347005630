import React from "react";

import BackArrow from "src/assets/icons/common/back-arrow.svg";

interface HeaderProps {
  backButton?: boolean;
  title?: string;
  rightButton?: boolean;
  rightButtonTitle?: string;
  onClickOfRightBtn?: () => void;
  onClickOfBackBtn?: () => void;
  className?: string;
}

export const Header: React.FC<HeaderProps> = (props) => {
  const {
    backButton = true,
    title = "Title",
    rightButton = false,
    rightButtonTitle = "Add",
    onClickOfRightBtn = () => {},
    onClickOfBackBtn = () => {},
    className,
  } = props;

  return (
    <header
      className={`${className} p-4 flex justify-between items-center bg-background`}
    >
      <div className="flex gap-5 overflow-hidden items-center">
        {backButton && (
          <div onClick={onClickOfBackBtn}>
            <img
              src={BackArrow}
              alt="Back Arrow"
              className="cursor-pointer w-6"
            />
          </div>
        )}
        <div className="overflow-hidden whitespace-nowrap text-ellipsis font-bold text-text-title text-lg">
          {title}
        </div>
      </div>
      {rightButton ? (
        <div
          className="text-primary text-sm font-bold flex items-center cursor-pointer"
          onClick={onClickOfRightBtn}
        >
          {rightButtonTitle}
        </div>
      ) : (
        ""
      )}
    </header>
  );
};
