import React, { ReactNode } from "react";

import Card from "src/components/atoms/Card/Card";
import ProgressIcon from "src/components/atoms/Icons/ProgressIcon";

import RIGHTICON from "../../../../assets/icons/progress/view-more-icon.svg";
import ViewMoreIcon from "src/components/atoms/Icons/ViewMoreIcon";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import HeartImage from "src/components/atoms/Icons/Progress/HeartIcon";

interface Props {
  image: ReactNode;
  progressTitle: string;
  progressSubText: string;
  vitalsLogged?: number;
  totalVitals?: number;
  vitalLogged: number | string;
  handleOnclick?: () => void;
}

const ProgressDetailCard: React.FC<Props> = ({
  image,
  progressTitle,
  vitalsLogged = 0,
  totalVitals = 0,
  progressSubText,
  vitalLogged,
  handleOnclick,
}) => {
  const theme = useSelector((state: RootState) => state.theme);
  const getBackgroundColor = (
    vitalsLogged: number,
    totalVitals: number
  ): { bgColor: string; textColor: string } => {
    if (totalVitals === 0)
      return { bgColor: "bg-gray-300", textColor: "text-text-primary" }; // Default gray
    const percentageLogged = (vitalsLogged / totalVitals) * 100;
    if (percentageLogged < 20) {
      return {
        bgColor: "bg-color-light-red",
        textColor: "text-color-dark-red",
      };
    } else if (percentageLogged >= 20 && percentageLogged <= 80) {
      return {
        bgColor: "bg-background-light-yellow",
        textColor: "text-color-dark-yellow",
      };
    } else {
      return {
        bgColor: "bg-color-light-green",
        textColor: "text-color-dark-green",
      };
    }
  };
  const { bgColor, textColor } = getBackgroundColor(vitalsLogged, totalVitals);
  return (
    <Card className="p-4 bg-background !rounded-3xl" onClick={handleOnclick}>
      <div className="flex flex-col gap-4">
        <div className="flex gap-3 flex-shrink-0">
          <div className="bg-background-ternary rounded-4.5 w-18 h-18  flex items-center justify-center flex-shrink-0">
            {image}
          </div>
          <div className="flex flex-col gap-1">
            <div className="font-semibold font-poppins text-text-primary text-base">
              {progressTitle}
            </div>
            <div className="text-sm text-text-secodary font-poppins">
              {progressSubText}
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className={`${bgColor} rounded-3xl px-3 py-1`}>
            <div
              className={`text-xs ${textColor} font-poppins font-medium leading-4`}
            >
              {vitalLogged} vitals logged
            </div>
          </div>
          <button>
            <ViewMoreIcon color={theme.colors["--color-button"]} />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ProgressDetailCard;
