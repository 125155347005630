import classNames from "classnames";
import React, { FC } from "react";
import Text from "src/components/atoms/Text";

interface RangePropsDataType {
  sections: number;
  labels: string[];
  position: number;
  markers: number[];
  value: string;
  type: string;
  className?: string;
}

interface RangePropsType {
  data: RangePropsDataType;
}

const Range: FC<RangePropsType> = ({ data }) => {
  const { sections, labels, position, markers, value } = data;
  const renderLines = (lineColorClass: string) => {
    let shortLines = Array.from(
      { length: sections === 2 ? 13 : 8 },
      (_, i) => i
    );

    return (
      <>
        <div
          className={classNames(
            "flex justify-center",
            sections !== 4 ? "w-full items-center" : "w-full items-end"
          )}
        >
          <div className={classNames("flex w-full items-end justify-evenly")}>
            {sections === 4 && position !== 0 && (
              <>
                <div className={classNames("h-1 w-0.5 ", lineColorClass)}></div>
                <div className={classNames("h-1 w-0.5 ", lineColorClass)}></div>
                <div className={classNames("h-1 w-0.5 ", lineColorClass)}></div>
              </>
            )}
            {Array.from(
              { length: sections !== 4 ? sections : sections - 1 },
              (_, i) => i
            ).map((sec) => (
              <>
                <div className={classNames("h-4 w-0.5", lineColorClass)}></div>
                {shortLines.map((line) => (
                  <div
                    className={classNames("h-1 w-0.5 ", lineColorClass)}
                  ></div>
                ))}
              </>
            ))}
            <div className={classNames("h-4 w-0.5", lineColorClass)}></div>
            {sections === 4 && position === 0 && (
              <>
                <div className={classNames("h-1 w-0.5 ", lineColorClass)}></div>
                <div className={classNames("h-1 w-0.5 ", lineColorClass)}></div>
                <div className={classNames("h-1 w-0.5 ", lineColorClass)}></div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderMarker = (markers: number[], markerColorClass: string) => {
    // let gapClass = sections >= 3 ? "gap-[2.88rem]" : "gap-[4.88rem]";
    let FourDigitPosition = {
      first: sections === 4 && position !== 0 ? "left-[12%]" : "left-[3%]",
      second: sections === 4 && position !== 0 ? "left-[40%]" : "left-[34%]",
      third: sections === 4 && position !== 0 ? "left-[68%]" : "left-[64%]",
      fourth: sections === 4 && position === 0 ? "left-[91%]" : "left-[96%]",
    };

    let ThreeDigitPosition = {
      first: "left-[3%]",
      second: "left-[50%]",
      third: "left-[96%]",
    };

    const getPositionFromIndex = (index: number, sections: number) => {
      if (sections >= 3) {
        if (index === 0) return FourDigitPosition.first;
        if (index === 1) return FourDigitPosition.second;
        if (index === 2) return FourDigitPosition.third;
        if (index === 3) return FourDigitPosition.fourth;
      } else {
        if (index === 0) return ThreeDigitPosition.first;
        if (index === 1) return ThreeDigitPosition.second;
        if (index === 2) return ThreeDigitPosition.third;
      }
    };
    return (
      <>
        <div
          className={classNames(
            "flex justify-center w-full items-center relative h-7"
          )}
        >
          {markers.map((marker, index) => (
            <Text
              variant="caption2-regular"
              className={classNames(
                markerColorClass,
                "absolute -translate-x-1/2",
                getPositionFromIndex(index, sections)
              )}
            >
              {marker}
            </Text>
          ))}
        </div>
      </>
    );
  };

  const renderLabel = (labels: string[], labelColorClass: string) => {
    let ThreeLabelPosition = {
      first: sections === 4 && position !== 0 ? "left-[25%]" : "left-[20%]",
      second: sections === 4 && position !== 0 ? "left-[51%]" : "left-[47%]",
      third: sections === 4 && position !== 0 ? "left-[80%]" : "left-[77%]",
    };

    let TwoLabelPosition = {
      first: "left-[27%]",
      second: "left-[72%]",
    };

    const getPositionFromIndex = (index: number, sections: number) => {
      if (sections >= 3) {
        if (index === 0) return ThreeLabelPosition.first;
        if (index === 1) return ThreeLabelPosition.second;
        if (index === 2) return ThreeLabelPosition.third;
      } else {
        if (index === 0) return TwoLabelPosition.first;
        if (index === 1) return TwoLabelPosition.second;
      }
    };
    return (
      <div
        className={classNames(
          "flex items-end justify-center w-[106%]  h-6 py-2"
        )}
      >
        <div className={classNames("flex w-full items-center relative ")}>
          {labels.map((label, index) => (
            <Text
              variant="caption2-regular"
              className={classNames(
                labelColorClass,
                "absolute -translate-x-1/2 text-nowrap",
                getPositionFromIndex(index, sections)
              )}
            >
              {label}
            </Text>
          ))}
        </div>
      </div>
    );
  };

  const renderPointer = (
    position: number,
    pointerColorClass: string,
    bgClass: string
  ) => {
    let leftPosition = "left-[30%]";
    if (sections === 4) {
      leftPosition =
        position === 1
          ? "left-[25%]"
          : position === 2
          ? "left-[50%]"
          : "left-[85%]";
    } else if (sections === 3) {
      leftPosition =
        position === 1
          ? "left-[25%]"
          : position === 2
          ? "left-[50%]"
          : "left-[80%]";
    } else if (sections === 2) {
      leftPosition = position === 1 ? "left-[30%]" : "left-[75%]";
    }
    return (
      <>
        <div className="h-3 relative w-full">
          <div
            className={classNames(
              "absolute -top-7 -translate-x-1/2",
              leftPosition
            )}
          >
            <Text
              variant="header-h1"
              className={classNames(
                "px-4 py-1 text-white font-bold rounded-lg border-none min-w-16 flex items-center justify-center",
                bgClass
              )}
            >
              {value}
            </Text>
            <div className="relative">
              <div
                className={classNames(
                  `w-0 h-0 absolute left-[35%] top-[-1px] border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] `,
                  pointerColorClass
                )}
              ></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getColorTheme = (key: string) => {
    switch (key) {
      case "too-high":
        return {
          line: "bg-line-too-high",
          bg: "bg-background-too-high",
          pointerBorder: "border-t-label-too-high",
          pointer: "bg-label-too-high",
          text: "text-text-too-high",
        };
      case "high":
        return {
          line: "bg-line-high",
          bg: "bg-background-high",
          pointerBorder: "border-t-label-high",
          pointer: "bg-label-high",
          text: "text-text-high",
        };
      case "normal":
        return {
          line: "bg-line-normal",
          bg: "bg-background-normal",
          pointerBorder: "border-t-label-normal",
          pointer: "bg-label-normal",
          text: "text-text-normal",
        };
      case "low":
        return {
          line: "bg-line-low",
          bg: "bg-background-low",
          pointerBorder: "border-t-label-low",
          pointer: "bg-label-low",
          text: "text-text-low",
        };
      default:
        return {
          line: "bg-line-normal",
          bg: "bg-background-normal",
          pointerBorder: "border-t-label-normal",
          pointer: "bg-label-normal",
          text: "text-text-normal",
        };
    }
  };

  return (
    <div>
      <div
        className={classNames(
          "w-50 h-22 rounded-xl py-1 flex flex-col relative items-start ",
          getColorTheme(data.type).bg,
          data.className,
          sections !== 4 ? "px-2" : position !== 0 ? "pr-2" : "pl-2"
        )}
      >
        <div className="w-full relative">
          {renderPointer(
            position,
            getColorTheme(data.type).pointerBorder,
            getColorTheme(data.type).pointer
          )}
          {renderLabel(labels, getColorTheme(data.type).text)}
          {renderLines(getColorTheme(data.type).line)}
          {renderMarker(markers, getColorTheme(data.type).text)}
        </div>
      </div>
    </div>
  );
};

export default Range;
