import { put, call, takeEvery, all } from "redux-saga/effects";
import { common } from "../../types";
import * as actions from "../slices/progressSlice";
import ProgressApi from "src/api/progress";
import { constants } from "src/constants/common";

const CategoryWiseRedingDataSaga = function* CategoryWiseRedingDataSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      ProgressApi.categoryWiseRedingData,
      payload
    );
    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }
    yield put(actions.progressCategorySuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.progressCategoryError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const ProgressRedingLogsSaga = function* ProgressRedingLogsSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      ProgressApi.fetchAllRedingsLogs,
      payload
    );
    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }
    yield put(
      actions.progressRedingLogsSuccess({
        ...response,
        page: Number(payload.page ?? "1"),
      })
    );
    resolve(response);
  } catch (error) {
    yield put(
      actions.progressRedingLogsError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const UpdatePatientReadingsSaga = function* updatePatientReadingsSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      ProgressApi.updatePatientReadings,
      payload
    );
    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }
    yield put(actions.updatePatientReadingsSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.updatePatientReadingsError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

function* progressSaga() {
  yield all([
    takeEvery(actions.updatePatientReadingsRequest, UpdatePatientReadingsSaga),
  ]);
  yield all([
    takeEvery(actions.progressCategoryRequest, CategoryWiseRedingDataSaga),
  ]);
  yield all([
    takeEvery(actions.progressRedingLogsRequest, ProgressRedingLogsSaga),
  ]);
}

export default progressSaga;
