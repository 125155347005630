// src/store/slices/dietSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BCA, common } from "src/types/index";

// Initial state for the diet slice
const initialState: BCA.BCASliceStateType = {
  BcaResult: {
    isLoading: false,
    data: "",
    error: "",
  },
  PatientData: {
    isLoading: false,
    data: {},
    error: "",
  },
  BcaLogs: {
    totalLogs: 0,
    isLoading: false,
    data: [],
    error: "",
    isLastPageReached: false,
  },
};

// Define the diet slice
const bcaSlice = createSlice({
  name: "bca",
  initialState,
  reducers: {
    setBcaResultRequest: (
      state: BCA.BCASliceStateType,
      _action: PayloadAction<any>
    ) => {
      state.BcaResult.isLoading = true;
      state.BcaResult.error = "";
    },
    setBcaResultSuccess: (
      state: BCA.BCASliceStateType,
      _action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.BcaResult.isLoading = false;
    },
    setBcaResultError: (
      state: BCA.BCASliceStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.BcaResult.isLoading = false;
    },
    downloadBcaReportRequest: (
      state: BCA.BCASliceStateType,
      _action: PayloadAction<any>
    ) => {
      state.BcaResult.isLoading = true;
      state.BcaResult.error = "";
    },
    downloadBcaReportSuccess: (
      state: BCA.BCASliceStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.BcaResult.isLoading = false;
    },
    downloadBcaReportError: (
      state: BCA.BCASliceStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.BcaResult.isLoading = false;
    },
    getBcaResultRequest: (
      state: BCA.BCASliceStateType,
      _action: PayloadAction<any>
    ) => {
      state.BcaResult.isLoading = true;
      state.BcaResult.error = "";
    },
    getBcaResultSuccess: (
      state: BCA.BCASliceStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.BcaResult.isLoading = false;
    },
    getBcaResultError: (
      state: BCA.BCASliceStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.BcaResult.isLoading = false;
    },
    getPatientDetailsForBCARequest: (
      state: BCA.BCASliceStateType,
      _action: PayloadAction<any>
    ) => {
      state.PatientData.isLoading = true;
      state.PatientData.error = "";
    },
    getPatientDetailsForBCASuccess: (
      state: BCA.BCASliceStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.PatientData.isLoading = false;
      state.PatientData.data = action.payload.data;
    },
    getPatientDetailsForBCAError: (
      state: BCA.BCASliceStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.PatientData.isLoading = false;
    },
    setPatientDetailsForBca: (
      state: BCA.BCASliceStateType,
      action: PayloadAction<{ dob: string; height: string; gender: string }>
    ) => {
      state.PatientData.data.dob = action.payload.dob;
      state.PatientData.data.height = action.payload.height;
      state.PatientData.data.gender = action.payload.gender;
    },
    getBcaLogsRequest: (
      state: BCA.BCASliceStateType,
      _action: PayloadAction<any>
    ) => {
      state.BcaLogs.isLoading = true;
      state.BcaLogs.error = "";

      if (
        _action.payload.payload.queryParams.page === 1 &&
        state.BcaLogs.isLastPageReached
      ) {
        state.BcaLogs.isLastPageReached = false;
      }
    },
    getBcaLogsSuccess: (
      state: BCA.BCASliceStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      if (action.payload.page === 1) {
        state.BcaLogs.totalLogs = action.payload.data.total_records;
        state.BcaLogs.data = action.payload.data.bca_logs;
      } else {
        state.BcaLogs.data = [
          ...state.BcaLogs.data,
          ...action.payload.data.bca_logs,
        ];
      }
      state.BcaLogs.isLastPageReached =
        !(action.payload.data.bca_logs ?? []).length ||
        state.BcaLogs.data.length >= state.BcaLogs.totalLogs ||
        action.payload.data.totalLogs === 0;
      state.BcaLogs.isLoading = false;
    },
    getBcaLogsError: (
      state: BCA.BCASliceStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.BcaLogs.isLoading = false;
      state.BcaLogs.isLastPageReached = true;
    },
  },
});

// Export actions and reducer
export const {
  setBcaResultRequest,
  setBcaResultSuccess,
  setBcaResultError,
  downloadBcaReportRequest,
  downloadBcaReportSuccess,
  downloadBcaReportError,
  getPatientDetailsForBCARequest,
  getPatientDetailsForBCASuccess,
  getPatientDetailsForBCAError,
  getBcaLogsRequest,
  getBcaLogsSuccess,
  getBcaLogsError,
  getBcaResultRequest,
  getBcaResultSuccess,
  getBcaResultError,
  setPatientDetailsForBca,
} = bcaSlice.actions;

export default bcaSlice.reducer;
