import { FC, useState } from "react";
import TabSwitcherV2 from "src/components/atoms/TabSwitcherV2";
import { ReactComponent as AchieveIcon } from "src/assets/icons/Achieve.svg"; // Import as React component
import { ReactComponent as HeartIcon } from "src/assets/icons/Heart.svg"; // Import as React component
import { ReactComponent as SmartwatchIcon } from "src/assets/icons/Smartwatch.svg"; // Import as React component
import Devices from "src/components/organisms/Progress/BCA/Devices";

type Tab = "Achieve" | "Vitals" | "Devices";
const ProgressSection: FC = () => {
  return (
    <div className="">
      {/* <TabSwitcherV2
        tabs={[
          // { label: "Achieve", key: "Achieve", icon: <AchieveIcon /> },
          { label: "Vitals", key: "Vitals", icon: <HeartIcon /> },
          { label: "Devices", key: "Devices", icon: <SmartwatchIcon /> },
        ]}
        activeTab={selectedTab}
        setActiveTab={(val: any) => setSelectedTab(val)}
      /> */}
      {/* <div>
        {selectedTab === "Devices" ? (
          <div className="p-4 pt-8">
            <Devices />{" "}
          </div>
        ) : selectedTab === "Vitals" ? (
          <VitalsProgressPage />
        ) : null}
      </div> */}
      <div className="p-4 pt-8">
        <Devices />{" "}
      </div>
    </div>
  );
};

export default ProgressSection;
