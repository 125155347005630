import moment from "moment";
import React from "react";
import Card from "src/components/atoms/Card/Card";

interface RecordedDataCardProps {
  readingName: string;
  readingValue: number;
  recordedDate: string;
}

const ProgressRecordedDataCard: React.FC<RecordedDataCardProps> = ({
  readingName,
  readingValue,
  recordedDate,
}) => {
  return (
    // <Card className="!rounded-3xl">
    <div className=" flex flex-col">
      <div className="flex flex-row justify-between items-center ">
        <div className="flex flex-col">
          <p className="text-text-primary text-base font-poppins font-semibold">
            {readingName} : {readingValue}
          </p>
        </div>
        <div>
          <p className="text-text-subtitle text-sm font-poppins">
            {recordedDate &&
              moment(recordedDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY")}
          </p>
        </div>
      </div>
    </div>
    // </Card>
  );
};

export default ProgressRecordedDataCard;
