import React, { FC } from "react";
import { THEME_COLORS } from "src/theme/colors";

import SyncImg from "src/assets/images/Sync.png";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import Text from "src/components/atoms/Text";
import classNames from "classnames";

interface SyncButtonPropsType {
  height?: string;
  width?: string;
  onClick?: () => void;
}

const SyncButton: FC<SyncButtonPropsType> = ({
  height = "h-16",
  width = "w-16",
  onClick,
}) => {
  const { clientSource } = useSelector((state: RootState) => state.auth);
  return (
    <div
      className={classNames("border:none rounded-full", height, width)}
      onClick={onClick}
      style={{
        boxShadow: `0.2px 5.7px 0px 0px ${THEME_COLORS[clientSource]["--color-button-green-secondary"]}`,
      }}
    >
      <div
        className={classNames(
          "bg-button-green-primary rounded-full flex flex-col items-center justify-center",
          height,
          width
        )}
      >
        <img src={SyncImg} className="h-5 w-5" />

        <Text variant="label-medium" className="text-white font-bold">
          Sync
        </Text>
      </div>
    </div>
  );
};

export default SyncButton;
