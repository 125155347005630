import React, { FC, useState } from "react";
import Range from "src/components/molecules/progress/BCA/Range";
import BCAVitalView from "src/components/molecules/progress/BCA/BCAVitalView";

import { BCA_KEY_PARAMETERS, GoodFlipScaleRange } from "src/constants/bca";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import BcaVitalInfo from "src/components/molecules/progress/BCA/BcaVitalInfo";
import { BcaParameterValueType } from "src/types/bca";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { bmrRange } from "src/helpers/trackyVitalsRanges";

interface ReadingCardPropsType {
  type?: string;
  readingData?: any;
}

const ReadingCard: FC<ReadingCardPropsType> = ({ type, readingData }) => {
  const { PatientData } = useSelector((state: RootState) => state.bca);
  let vitalsRange: any = {
    ...readingData.ranges,
  };
  if (!vitalsRange.bmr) {
    vitalsRange = {
      ...readingData.ranges,
      bmr: bmrRange(PatientData?.data?.gender === "M"),
    };
  }
  const [selectedParams, setSelectedParams] = useState<BcaParameterValueType>();
  const [infoBottomSheetOpen, setInfoBottomSheetOpen] =
    useState<boolean>(false);

  const calculateRange = (readingData: any) => {
    const key =
      readingData.key.toLowerCase() === "skeletal_muscle_kg"
        ? "skeletal_muscle"
        : readingData.key.toLowerCase() === "subcutaneous_fat_kg"
        ? "subcutaneous_fat"
        : readingData.key.toLowerCase() === "fat_mass"
        ? "fat"
        : readingData.key.toLowerCase() === "subcutaneous_fat_percent"
        ? "subcutaneous_fat"
        : readingData.key.toLowerCase();

    const ranges = vitalsRange[key];
    const defaultRangeType = {
      type: "normal",
      position: 1,
      sections: 1,
      labels: ["Normal", "High"],
      markers: [],
    };

    if (!ranges) return defaultRangeType;

    const sections = ranges.length;
    const labels = ranges.map((range: any) => range.label);
    const markers = ranges
      .map((item: any) => parseFloat(item.from.toFixed(1)))
      .concat(parseFloat(ranges[ranges.length - 1].to.toFixed(1)));

    const index = ranges.findIndex(
      (item: any) =>
        readingData.value >= item.from && readingData.value <= item.to
    );

    const rangeIndex =
      index !== -1
        ? index
        : readingData.value < ranges[0].from
        ? 0
        : ranges.length - 1;

    const range = ranges[rangeIndex];
    const type =
      range.label === "Too High" ? "too-high" : range.label.toLowerCase();

    const adjustedLabels = [...labels];
    const adjustedMarkers = [...markers];
    let adjustedPosition = rangeIndex + 1;

    // Adjust for sections = 4 logic
    if (sections === 4) {
      if (adjustedPosition === 1) {
        adjustedLabels.pop();
        adjustedMarkers.pop();
      } else if (adjustedPosition === 4) {
        adjustedLabels.shift();
        adjustedMarkers.shift();
        adjustedPosition--;
      } else {
        adjustedLabels.shift();
        adjustedMarkers.shift();
        adjustedPosition--;
      }
    }

    return {
      type,
      position: adjustedPosition,
      sections,
      labels: adjustedLabels,
      markers: adjustedMarkers,
    };
  };
  const RenderCard = (reading: any, value: number, className?: string) => {
    return (
      <>
        <Range
          data={{
            className: className,
            type: reading.type,
            position: reading.position,
            sections: reading.sections,
            labels: reading.labels,
            markers: reading.markers,
            value: value.toFixed(2).toString(),
          }}
        />
      </>
    );
  };
  return (
    <>
      <div className="flex flex-col gap-6 mt-6 select-none">
        {readingData && readingData.length !== 0
          ? Object.keys(readingData)?.map((data: any) => {
              const parameter = BCA_KEY_PARAMETERS[data];
              let rangeKey =
                data.toLowerCase() === "skeletal_muscle_kg"
                  ? "skeletal_muscle"
                  : data.toLowerCase() === "subcutaneous_fat_kg"
                  ? "subcutaneous_fat"
                  : data.toLowerCase() === "fat_mass"
                  ? "fat"
                  : data.toLowerCase() === "subcutaneous_fat_percent"
                  ? "subcutaneous_fat"
                  : data.toLowerCase();
              if (
                !parameter ||
                !readingData[parameter.id] ||
                !vitalsRange[rangeKey.toLowerCase()]
              ) {
                return null;
              }
              const reading = readingData[parameter.id];
              return (
                <div
                  className="bg-white p-4 pt-8 flex items-start justify-between gap-1 rounded-2xl"
                  key={data}
                >
                  <BCAVitalView
                    parameter={parameter}
                    onClickInfo={() => {
                      setSelectedParams(parameter);
                      setInfoBottomSheetOpen(true);
                    }}
                  />
                  {RenderCard(
                    calculateRange({
                      key: data,
                      value: reading,
                    }),
                    parseFloat(reading)
                  )}
                </div>
              );
            })
          : "no data found"}
      </div>
      {selectedParams && (
        <BottomSheetSelect
          open={infoBottomSheetOpen}
          className="relative z-20"
          close={() => setInfoBottomSheetOpen(false)}
        >
          <BcaVitalInfo
            parameter={selectedParams}
            ReadingCard={RenderCard(
              calculateRange({
                key: selectedParams.id,
                value: readingData[selectedParams.id],
              }),
              readingData[selectedParams.id],
              "!w-full"
            )}
          />
        </BottomSheetSelect>
      )}
    </>
  );
};

export default ReadingCard;
