import React, { FC, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

import Text from "src/components/atoms/Text";
import SyncButton from "../SyncButton";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import sendMessageToParent from "src/helpers/parentMessageHandler";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import moment from "moment";
import { calculateAge } from "src/helpers/common";

interface DeviceCardPropsType {
  CardImageComponent: ReactNode;
  device: "smart_scale" | "glucose_meter" | "spirometer";
  title: string;
  lastSync?: string;
}

const DevicesCard: FC<DeviceCardPropsType> = ({
  CardImageComponent,
  device,
  title,
  lastSync,
}) => {
  const navigate = useNavigate();
  const [openSyncModal, setOpenSyncModal] = useState<boolean>(false);
  const [selectDevice, setSelectDevice] = useState<"Good Flip" | "Lifetrons">(
    "Good Flip"
  );
  const { PatientData } = useSelector((state: RootState) => state.bca);
  const { user } = useSelector((state: RootState) => state.auth);

  const handleSubmitDevice = async () => {
    try {
      if (device === "smart_scale") {
        setOpenSyncModal(false);
        if (selectDevice === "Good Flip") {
          navigate("/progress/device-details");
        } else {
          sendMessageToParent("bca_device_connection", {
            type: "bca",
            device_type: "lifetrons",
          });
        }
      }
    } catch (err) {
      // error handling
    }
  };

  const handleViewLogPress = () => {
    if (device === "smart_scale") {
      navigate("/progress/device-logs");
    } else if (device === "spirometer") {
      sendMessageToParent("spirometer", {
        subAction: "viewlogs",
      });
    }
  };

  const handleOnPressSync = () => {
    if (device === "smart_scale") {
      // setOpenSyncModal(true);

      if (
        !!PatientData?.data?.dob &&
        !!PatientData?.data?.gender &&
        !!PatientData?.data?.height
      ) {
        sendMessageToParent("init_device_bottomsheet", {
          age: Number(calculateAge(PatientData.data.dob, "YYYY-MM-DD")),
          userId: user.data.patientID,
          height: Number(PatientData?.data.height),
          gender:
            PatientData.data?.gender === "M"
              ? 1
              : user.platform === "android"
              ? 2
              : 0,
          dob: PatientData.data?.dob,
        });
      } else {
        navigate("/progress/device-details");
      }
    } else if (device === "spirometer") {
      sendMessageToParent("spirometer", {
        subAction: "sync",
      });
    }
  };

  return (
    <div
      className="bg-[#FFFBEE] w-full rounded-2xl border border-color-gold-secondary
"
    >
      <div className="flex px-4 pt-4 gap-3 items-center">
        <div className="bg-color-gold-secondary rounded-lg relative h-[100px] w-[100px] overflow-hidden flex items-center justify-center">
          {CardImageComponent}
        </div>
        <div>
          <Text variant="title-small" className="text-text-primary">
            {title}
          </Text>
          <Text variant="label-medium" className="text-text-secodary">
            {lastSync &&
              moment(lastSync, "YYYY-MM-DD HH:mm:ss").format(
                "DD MMM | hh:mm A"
              )}
          </Text>
        </div>
      </div>

      <div className="w-full  mt-10 flex items-center  rounded-2xl rounded-t-none">
        <div className="w-full rounded-b-xl bg-color-gold-secondary h-10 pl-4 flex items-center relative">
          <Text
            variant="label-large"
            className="underline text-button-green-primary font-semibold"
            onClick={handleViewLogPress}
          >
            View Logs
          </Text>
          <div className="absolute right-[10%] -top-8">
            <SyncButton
              height="h-13.5"
              width="w-14"
              onClick={() => {
                handleOnPressSync();
              }}
            />
          </div>
        </div>
      </div>
      <BottomSheetSelect
        open={openSyncModal && device === "smart_scale"}
        close={() => setOpenSyncModal(false)}
      >
        <div className="p-4 flex flex-col items-center">
          <section>
            <h1 className="text-xl font-bold text-text-secondary">
              Select Device
            </h1>
          </section>
          <hr className="w-full h-0.5 bg-background" />
          <div className="mt-2 w-full ">
            <div>
              <div
                className="flex items-center rounded-[5px] bg-color-extra-light-gray p-2 mt-2"
                onClick={() => setSelectDevice("Good Flip")}
              >
                <div
                  className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                    selectDevice === "Good Flip"
                      ? `border-primary bg-white`
                      : "border-gray-400"
                  }`}
                >
                  {selectDevice === "Good Flip" && (
                    <div className="w-2.5 h-2.5 rounded-full bg-primary"></div>
                  )}
                </div>
                <div className="text-left ml-2 text-gray-400">Good Flip</div>
              </div>
              <div
                className="flex items-center rounded-[5px] bg-color-extra-light-gray p-2 mt-2"
                onClick={() => {
                  setSelectDevice("Lifetrons");
                }}
              >
                <div
                  className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                    selectDevice === "Lifetrons"
                      ? `border-primary bg-white`
                      : "border-gray-300"
                  }`}
                >
                  <div
                    className={`w-2.5 h-2.5 rounded-full  ${
                      selectDevice === "Lifetrons"
                        ? `bg-primary`
                        : "bg-gray-300"
                    }`}
                  ></div>
                </div>
                <div className="text-left font-[400] ml-2 text-gray-400">
                  Lifetrons
                </div>
              </div>
              <div
                className=" bg-primary rounded-[5px] text-white p-2 text-center mt-2"
                onClick={() => {
                  handleSubmitDevice();
                }}
              >
                Select
              </div>
            </div>
          </div>
        </div>
      </BottomSheetSelect>
    </div>
  );
};

export default DevicesCard;
{
}
