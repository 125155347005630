import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import useQueryParams from "src/hooks/useQueryParams";
import { RootState } from "src/store";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import Text from "src/components/atoms/Text";
import { THEME_COLORS } from "src/theme/colors";
import {
  downloadBcaReportRequest,
  getBcaResultRequest,
  setBcaResultRequest,
} from "src/store/slices/bcaSlice";

import { ReactComponent as ShareIcon } from "src/assets/icons/progress/share.svg";
import DynamicSVG from "src/components/atoms/DynamicSVG";
import ScaleImage from "src/assets/images/devices/bca_detail_machine.png";
import ReadingCard from "src/components/organisms/Progress/BCA/ReadingCard";
import moment from "moment";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";
import { trackyVitalsRange } from "src/helpers/trackyVitalsRanges";
import sendMessageToParent from "src/helpers/parentMessageHandler";

const DeviceResultPage: FC = () => {
  const { clientSource } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = useQueryParams(location.search);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  const [reportUrl, setReportUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSocialBottomSheet, setOpenSocialBottomSheet] =
    useState<boolean>(false);
  const lastMeasureData = queryParams["syncDate"] ?? new Date().toISOString();
  const [readingData, setReadingData] = useState<any>();
  const [shareLoading, setShareLoading] = useState<boolean>(false);

  useEffect(() => {
    if (queryParams) {
      if (queryParams?.eventData) {
        const parseData = JSON.parse(queryParams?.eventData);
        let parseReadingData = parseData.data;
        const ranges = trackyVitalsRange(parseData.userInfo);
        parseReadingData.ranges = ranges;
        if (parseReadingData && queryParams?.eventData) {
          let payload: any = {
            synced_device: "GoodFlip",
            device_id: "GoodFlip",
            weight: parseReadingData.weight,
            BMI: parseReadingData.BMI,
            BMR: parseReadingData.BMR,
            fat_mass: parseReadingData.fat_mass,
            muscle_mass: parseReadingData.muscle_mass,
            bone_mass: parseReadingData.bone_mass,
            hydration: parseReadingData.hydration,
            protein: parseReadingData.protein,
            subcutaneous_fat_percent: parseReadingData.subcutaneous_fat_percent,
            visceral_fat: parseReadingData.visceral_fat,
            ranges: ranges,
          };
          const extraKeys = [
            "skeletal_muscle_kg",
            "skeletal_muscle_percent",
            "subcutaneous_fat_kg",
            "fat_mass_kg",
            "hydration_kg",
            "protein_kg",
            "metabolic_age",
          ];
          extraKeys.forEach((key) => {
            if (parseReadingData?.[key] !== undefined) {
              payload[key] = parseReadingData[key];
            }
          });
          setBCAResult(payload);
        }
        setReadingData(parseReadingData);
      } else if (queryParams["logId"]) {
        setLoading(true);
        const logId = queryParams["logId"];
        setDataUpdated(true);
        if (logId) {
          let payload = {
            queryParams: {
              bcaLogId: logId,
            },
          };
          new Promise((resolve, reject) => {
            dispatch(
              getBcaResultRequest({
                payload,
                resolve,
                reject,
              })
            );
          })
            .then((data: any) => {
              setReadingData(data.data.bca_info);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }
  }, []);

  const setBCAResult = (payload: any) => {
    new Promise((resolve, reject) => {
      dispatch(
        setBcaResultRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((data) => {
        sendMessageToParent("updateBcaVitals");
        setDataUpdated(true);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const onPressShare = () => {
    setShareLoading(true);
    let payload: any = {};
    if (queryParams["logId"]) {
      payload.bca_sync_logs_id = queryParams["logId"];
    }
    new Promise((resolve, reject) => {
      dispatch(
        downloadBcaReportRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then(async (data: any) => {
        let url = data.data;

        if (url) {
          sendMessageToParent("sharePdf", {
            url: url,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setShareLoading(false);
      });
  };

  return (
    <div className="bg-background">
      <div className="border border-b border-[#E4E7EC]">
        <ProgressBackIcon
          onClick={() => {
            navigate("/progress/device-logs");
          }}
        />
      </div>
      {loading ? (
        <div className="h-full flex items-center justify-center">
          <SpinnerLoader />
        </div>
      ) : (
        <div>
          <div className="bg-color-accent-indigo pt-4 px-12  flex flex-col items-center justify-center overflow-hidden">
            <div className="flex flex-col gap-2 ">
              <Text variant="headline-medium" align="center">
                Body Composition Analysis Log
              </Text>
              <Text
                variant="body-medium"
                align="center"
                className="text-text-ternary"
              >
                GoodFlip app has received the following data from your smart
                scale
              </Text>
            </div>
            {dataUpdated && (
              <div
                className="z-10 flex items-center justify-center gap-1 pt-3"
                onClick={onPressShare}
              >
                {shareLoading ? (
                  <Text
                    variant="body2-bold"
                    className="font-semibold text-button-green-primary z-10"
                  >
                    Loading...
                  </Text>
                ) : (
                  <>
                    <Text
                      variant="body2-bold"
                      className="font-semibold text-button-green-primary z-10"
                    >
                      Share Report
                    </Text>
                    <DynamicSVG
                      size="h-3 w-3"
                      color={
                        THEME_COLORS[clientSource][
                          "--color-button-green-primary"
                        ]
                      }
                    >
                      <ShareIcon />
                    </DynamicSVG>
                  </>
                )}
              </div>
            )}

            <div className="relative -mt-5 z-0">
              <div className="relative top-6">
                <img src={ScaleImage} className="h-auto w-61 " />
              </div>
              <div className="absolute top-10 left-[52%] z-10 -translate-x-1/2 flex">
                <Text
                  variant="digital"
                  className="text-2xl text-color-red-scale-value"
                >
                  {Number(readingData?.weight ?? "0").toFixed(2)}
                </Text>
                <Text
                  variant="caption1-regular"
                  className=" text-color-red-scale-value"
                >
                  kg
                </Text>
              </div>
            </div>
          </div>
          <div className="p-4 z-10">
            <div className="p-3 bg-color-gold-secondary rounded-xl">
              <Text variant="label-small" className="text-text-primary">
                Last measurement taken on
              </Text>
              <Text
                variant="body-medium"
                className="font-bold text-text-primary"
              >
                {moment(lastMeasureData).format("Do MMM YYYY; hh:mm A")}
              </Text>
            </div>
            <div>
              {readingData && <ReadingCard readingData={readingData} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceResultPage;
