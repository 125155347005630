import React, { useEffect, useRef, useState } from "react";

import Card from "src/components/atoms/Card/Card";
import RangeMeter from "src/components/atoms/ProgressRangeMeter";

import RIGHTICON from "../../../../assets/icons/progress/view-more-icon.svg";
import moment from "moment";
import InfoIcon from "src/components/atoms/Icons/InfoIcon";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import CloseCircleIcon from "src/components/atoms/Icons/CloseCircleIcon";
import ViewMoreIcon from "src/components/atoms/Icons/ViewMoreIcon";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

interface ProgressResultDetailsCardProps {
  progressTitle: string;
  progressUnit: string;
  vitalBg: string;
  vitalTextColor: string;
  loggedDate: string;
  handleOnclick: () => void;
  activeRange?: number;
  startRange?: number;
  endRange?: number;
  info: string;
  valueObject?: string;
  readingLimits?: {
    [key: string]: {
      max_limit: number;
      min_limit: number;
    };
  };
  renderObject?: boolean;
  key: string;
  validation: string;
}

const ProgressResultDetailsCard: React.FC<ProgressResultDetailsCardProps> = ({
  progressTitle,
  progressUnit,
  key,
  loggedDate,
  handleOnclick,
  startRange = null,
  endRange = null,
  activeRange = null,
  info = "",
  renderObject,
  readingLimits,
  valueObject,
  validation,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef<HTMLDivElement>(null);

  const theme = useSelector((state: RootState) => state.theme);

  const togglePopup = () => setIsOpen(!isOpen);

  const getStatusColor = (
    value: number,
    min: number,
    max: number
  ): { backgroundColor: string; textColor: string } => {
    if (!value) {
      return {
        backgroundColor: "bg-color-background-secondary", // if no value
        textColor: "text-color-text-ternary",
      };
    } else if (value > max || value < min) {
      return {
        backgroundColor: "bg-color-light-red", // Red for out of range
        textColor: "text-color-dark-red",
      };
    } else if (value === min || value === max) {
      return {
        backgroundColor: "bg-background-light-yellow", // Yellow for min or max
        textColor: "text-color-extra-dark-yellow",
      };
    } else {
      return {
        backgroundColor: "bg-color-light-green", // Green for within range
        textColor: "text-color-extra-dark-green",
      };
    }
  };

  const renderBloodPressureCard = (valueObject: string) => {
    if (readingLimits) {
      const rangeObjet: any = readingLimits;
      const valueObj = JSON.parse(valueObject);
      return (
        <>
          {Object.keys(valueObj).map((key: any) => (
            <>
              <div className="flex justify-between gap-4">
                <div className="w-full">
                  <div
                    className={` ${
                      getStatusColor(
                        renderObject
                          ? Number(valueObj?.[key])
                          : Number(activeRange),
                        Number(Number(rangeObjet?.[key]["min_limit"])),
                        Number(Number(rangeObjet?.[key]["max_limit"]))
                      ).backgroundColor
                    } h-16 rounded-2xl flex px-3 flex-col w-full justify-center `}
                  >
                    <p
                      className={`${
                        getStatusColor(
                          renderObject
                            ? Number(valueObj?.[key])
                            : Number(activeRange),
                          Number(Number(rangeObjet?.[key]["min_limit"])),
                          Number(Number(rangeObjet?.[key]["max_limit"]))
                        ).textColor
                      } font-poppins font-semibold text-xl`}
                    >
                      {JSON.parse(valueObject)?.[key]
                        ? Number(valueObj?.[key])
                        : "--"}
                    </p>
                    <p
                      className={`${
                        getStatusColor(
                          renderObject
                            ? Number(valueObj?.[key])
                            : Number(activeRange),
                          Number(Number(rangeObjet?.[key]["min_limit"])),
                          Number(Number(rangeObjet?.[key]["max_limit"]))
                        ).textColor
                      } font-poppins text-xs `}
                    >
                      Unit:{" "}
                      <span
                        className={`${
                          getStatusColor(
                            renderObject
                              ? Number(valueObj?.[key])
                              : Number(activeRange),
                            Number(Number(rangeObjet?.[key]["min_limit"])),
                            Number(Number(rangeObjet?.[key]["max_limit"]))
                          ).textColor
                        } text-xs font-poppins font-semibold`}
                      >
                        {progressUnit}
                      </span>
                    </p>
                  </div>
                  <p className="w-full text-center font-light text-sm font-poppins">
                    {key}
                  </p>
                </div>
                <div className="w-full">
                  <div className="h-16 rounded-2xl w-full items-center flex">
                    <RangeMeter
                      leftRange={
                        rangeObjet?.[key]["min_limit"]
                          ? Number(rangeObjet?.[key]["min_limit"])
                          : null
                      }
                      rightRange={
                        rangeObjet?.[key]["max_limit"]
                          ? Number(rangeObjet?.[key]["max_limit"])
                          : null
                      }
                      value={valueObj?.[key] ? Number(valueObj?.[key]) : null}
                    />
                  </div>
                  <div>
                    {activeRange &&
                      JSON.parse(validation)?.[key]["min_limit"] &&
                      JSON.parse(validation)?.[key]["max_limit"] && (
                        <p className="w-full flex items-center justify-center text-text-secondary text-xs font-poppins">
                          {Number(Number(valueObj?.[key])) ===
                            Number(rangeObjet?.[key]["min_limit"]) ||
                          Number(Number(JSON.parse(valueObject)?.[key])) ===
                            Number(rangeObjet?.[key]["max_limit"])
                            ? "On the border"
                            : Number(Number(valueObj?.[key])) <
                                Number(rangeObjet?.[key]["min_limit"]) ||
                              Number(Number(valueObj?.[key])) >
                                Number(rangeObjet?.[key]["max_limit"])
                            ? "Out of range"
                            : "Within range"}
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </>
      );
    }
  };

  const value = Number(activeRange);
  const { backgroundColor, textColor } = getStatusColor(
    value,
    Number(startRange),
    Number(endRange)
  );
  return (
    <div className="w-full h-auto">
      <Card
        className="!rounded-3xl flex flex-col gap-4 p-4"
        onClick={(e) => {
          if (!isOpen) {
            handleOnclick();
          }
        }}
      >
        <div className="flex justify-between gap-4 items-center w-full">
          <div className="flex font-semibold text-base font-poppins text-text-primary items-center">
            <div className="max-w-56 truncate">{progressTitle}</div>

            <div
              className="relative"
              ref={iconRef}
              onClick={(e) => {
                e.stopPropagation();
                togglePopup();
              }}
            >
              {info && info.length > 0 && (
                <InfoIcon className="ml-2" color={"gray"} />
              )}
            </div>
          </div>

          <button>
            <ViewMoreIcon color={theme.colors["--color-button"]} />
          </button>
        </div>

        {renderObject && valueObject ? (
          <>{renderBloodPressureCard(valueObject)}</>
        ) : (
          <div className="flex justify-between gap-4">
            <div
              className={` ${backgroundColor} h-16 rounded-2xl flex px-3 flex-col w-full justify-center `}
            >
              <p className={`${textColor} font-poppins font-semibold text-xl`}>
                {activeRange ? Number(activeRange) : "--"}
              </p>
              <p className={`${textColor} font-poppins text-xs `}>
                Unit:{" "}
                <span
                  className={`${textColor} text-xs font-poppins font-semibold`}
                >
                  {progressUnit}
                </span>
              </p>
            </div>
            <div className="h-16 rounded-2xl w-full items-center flex">
              <RangeMeter
                leftRange={startRange ? Number(startRange) : null}
                rightRange={endRange ? Number(endRange) : null}
                value={activeRange ? Number(activeRange) : null}
              />
            </div>
          </div>
        )}

        <div className="flex mt-1">
          <div className="w-[50%]">
            {loggedDate != "" ? (
              <p className="text-text-ternary text-xs font-poppins">
                Logged on{" "}
                <span className="font-semibold font-poppins">
                  {moment(loggedDate, "YYYY-MM-DD HH:mm:ss").format(
                    "DD MMM YYYY"
                  )}
                </span>
              </p>
            ) : (
              ""
            )}
          </div>
          {!renderObject && (
            <div className="w-[50%] flex items-center justify-center">
              {activeRange && startRange && endRange && (
                <p className="text-text-secondary text-xs font-poppins">
                  {Number(activeRange) === Number(startRange) ||
                  Number(activeRange) === Number(endRange)
                    ? "Borderline"
                    : Number(activeRange) < Number(startRange) ||
                      Number(activeRange) > Number(endRange)
                    ? "Out of range"
                    : "Within range"}
                </p>
              )}
            </div>
          )}
        </div>
      </Card>

      <BottomSheetSelect open={isOpen} close={() => setIsOpen(false)}>
        <section className="p-4 flex justify-between ">
          <h1 className="text-xl font-bold text-text-secodary">
            {progressTitle}
          </h1>
          <div onClick={() => setIsOpen(false)}>
            <CloseCircleIcon />
          </div>
        </section>
        <hr className="w-full h-[2px] bg-background" />
        <section className="p-4">{info}</section>
      </BottomSheetSelect>
    </div>
  );
};
export default ProgressResultDetailsCard;
