import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";

import { RootState } from "src/store";
import Button from "src/components/atoms/Button/Button";
import ErrorText from "src/components/atoms/ErrorText";
import Text from "src/components/atoms/Text";
import TextInput from "src/components/atoms/TextInput";
import {
  calculateAge,
  convertToFeetInches,
  formatFeetAndInchesToString,
} from "src/helpers/common";
import sendMessageToParent from "src/helpers/parentMessageHandler";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import {
  setUserData,
  updatePatientDetailsRequest,
} from "src/store/slices/authSlice";

import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import "react-calendar/dist/Calendar.css";
import { setPatientDetailsForBca } from "src/store/slices/bcaSlice";
const DeviceProvideDetails: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);
  const { PatientData } = useSelector((state: RootState) => state.bca);
  const [showError, setShowError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [isHeightChanged, setIsHeightChanged] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    feet: PatientData.data?.height
      ? convertToFeetInches(Number(PatientData.data?.height)).feet.toString()
      : "",
    inches: PatientData?.data.height
      ? convertToFeetInches(Number(PatientData.data?.height)).inches.toString()
      : "",
    gender: PatientData.data?.gender ?? "",
    dob: PatientData.data?.dob
      ? moment(PatientData.data?.dob, "YYYY-MM-DD").toDate()
      : new Date(),
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setShowError(false);

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (
      !isValidDateFormate(moment(formData.dob).format("YYYY-MM-DD")) ||
      Number(formData.feet) > 8 ||
      Number(formData.feet ?? "0") <= 0 ||
      Number(formData.inches ?? "0") < 0 ||
      Number(formData.inches) > 12 ||
      formData.gender.length === 0
    ) {
      setShowError(true);
      return;
    }
    setIsLoading(true);
    const value = formatFeetAndInchesToString(formData.feet, formData.inches);

    const payload = {
      height: value,
      height_unit: "cm",
      updateHeightWeight: 1,
      gender: formData.gender,
      dob: moment(formData.dob).format("YYYY-MM-DD"),
    };

    new Promise((resolve, reject) => {
      dispatch(
        updatePatientDetailsRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then(() => {
        // sendMessageToParent("syncDevice", {
        //   age: Number(
        //     calculateAge(moment(payload.dob).format("YYYY-MM-DD"), "YYYY-MM-DD")
        //   ),
        //   userId: user.data.patientID,
        //   height: Number(value),
        //   gender: formData.gender === "M" ? 1 : 2,
        // });
        sendMessageToParent("init_device_bottomsheet", {
          age: Number(
            calculateAge(moment(payload.dob).format("YYYY-MM-DD"), "YYYY-MM-DD")
          ),
          userId: user.data.patientID,
          height: Number(value),
          gender:
            formData.gender === "M" ? 1 : user.platform === "android" ? 2 : 0,
          dob: moment(payload.dob).format("YYYY-MM-DD"),
        });
        sendMessageToParent("updateUserInfo");
        dispatch(
          setPatientDetailsForBca({
            height: payload.height,
            gender: payload.gender,
            dob: payload.dob,
          })
        );
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const isValidDateFormate = (date: string) => {
    console.log(
      "date==>",
      date,
      moment(date, "YYYY-MM-DD", true).isValid() &&
        !moment(date, "YYYY-MM-DD").isAfter(moment())
    );
    return (
      moment(date, "YYYY-MM-DD", true).isValid() &&
      !moment(date, "YYYY-MM-DD").isAfter(moment())
    );
  };
  return (
    <>
      <div className="h-screen bg-background-light-blue">
        <div className="border border-b border-[#E4E7EC]">
          <ProgressBackIcon
            onClick={() => {
              navigate("/progress/progress-device-section?active_tab=Devices");
            }}
          />
        </div>
        <div className="px-4 h-[calc(100dvh-139px)] overflow-scroll">
          <Text
            variant="title-large"
            className="text-2xl font-medium text-text-primary mt-4"
          >
            Enter your details
          </Text>
          <Text
            variant="body-small"
            className="text-text-primary opacity-80 mb-2 mt-2 text-base leading-6"
          >
            Enter the following details to continue
          </Text>
          <div className="flex flex-col gap-4">
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Text variant="body-large">
                Enter your height<span className="text-label-high">*</span>
              </Text>
              <div className="flex gap-4">
                <div>
                  <TextInput
                    label="Feet"
                    placeholder="Enter Feet"
                    name="feet"
                    type="text"
                    onChange={(e) => {
                      setIsHeightChanged(true);
                      const newValue = e.target.value;
                      if (/^\d*$/.test(newValue)) {
                        handleChange(e);
                      }
                    }}
                    value={formData.feet}
                  />
                  {(showError || isHeightChanged) &&
                    (Number(formData.feet) > 8 ||
                      Number(formData.feet ?? "0") <= 0) && (
                      <ErrorText message="Feet should be between 1 and 8." />
                    )}
                </div>
                <div>
                  <TextInput
                    label="Inches"
                    placeholder="Enter Inches"
                    name="inches"
                    type="text"
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (/^\d*$/.test(newValue)) {
                        handleChange(e);
                      }
                    }}
                    value={formData.inches}
                  />
                  {(showError || isHeightChanged) &&
                    (Number(formData.inches) > 12 ||
                      Number(formData.inches ?? "0") < 0) && (
                      <ErrorText message="Inches should be less than 12." />
                    )}
                </div>
              </div>
              {/* {showError &&
                (Number(formData.feet) > 10 ||
                  Number(formData.feet ?? "0") === 0 ||
                  Number(formData.inches) > 11) && (
                  <ErrorText message="Please enter valid height input (feet <= 10 and inches <= 12)" />
                )} */}
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
              <Text variant="body-large">
                What is your gender?<span className="text-label-high">*</span>
              </Text>
              <div className="flex flex-col gap-3 mt-5">
                <div
                  className="flex items-center rounded-[5px] w-[94%]  mt-2"
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, gender: "M" }))
                  }
                >
                  <div
                    className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                      formData.gender === "M"
                        ? `border-primary bg-white`
                        : "border-gray-400"
                    }`}
                  >
                    {formData.gender === "M" && (
                      <div className="w-2.5 h-2.5 rounded-full bg-primary"></div>
                    )}
                  </div>
                  <div className="text-left ml-2 text-text-primary">Male</div>
                </div>
                <div
                  className="flex items-center rounded-[5px] w-[94%]  mt-2"
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, gender: "F" }))
                  }
                >
                  <div
                    className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                      formData.gender === "F"
                        ? `border-primary bg-white`
                        : "border-gray-400"
                    }`}
                  >
                    {formData.gender === "F" && (
                      <div className="w-2.5 h-2.5 rounded-full bg-primary"></div>
                    )}
                  </div>
                  <div className="text-left ml-2 text-text-primary">Female</div>
                </div>
              </div>
              {showError && formData.gender.length === 0 && (
                <ErrorText message="Please select gender." />
              )}
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Text variant="body-large">
                Enter your date of birth
                <span className="text-label-high">*</span>
              </Text>
              <TextInput
                label=""
                placeholder="DD/MM/YYYY"
                name="dob"
                type="text"
                value={moment(formData.dob).format("DD/MM/YYYY")}
                onClick={() => {
                  setIsCalendarOpen(true);
                }}
              />

              {showError &&
                !isValidDateFormate(
                  moment(formData.dob).format("YYYY-MM-DD")
                ) && (
                  <ErrorText message="Please enter valid date(dd/mm/yyyy)" />
                )}
            </div>
          </div>
        </div>
        <div className="p-4 bg-white absolute bottom-0 w-full border-t-2">
          <Button
            label="Submit Details"
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
            className="mb-2 h-12 w-full !rounded-xl"
          />
        </div>
      </div>

      <BottomSheetSelect
        open={isCalendarOpen}
        close={() => setIsCalendarOpen(false)}
      >
        <div>
          <section className="p-4">
            <h1 className="text-xl font-bold text-text-secodary">
              Select Birth Date
            </h1>
          </section>
          <hr className="w-full h-[2px] bg-background" />
          <div className="p-6 flex items-center justify-center">
            <Calendar
              onChange={(data: any) => {
                if (data)
                  setFormData((pre) => ({
                    ...pre,
                    dob: moment(data).toDate(),
                  }));
                setIsCalendarOpen(false);
              }}
              value={formData.dob}
              className="p-4 rounded-md"
            />
          </div>
        </div>
      </BottomSheetSelect>
    </>
  );
};

export default DeviceProvideDetails;
