import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { useDispatch } from "react-redux";

import { Header } from "src/components/molecules/Header";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import ProgressRecordedDataCard from "src/components/molecules/progress/ProgressRecordedDataCard";
import {
  progressRedingLogsRequest,
  setRedirectFromSearch,
} from "src/store/slices/progressSlice";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import { hideLoader, showLoader } from "src/store/slices/loaderSlice";
import { toast } from "react-toastify";
import LogVitalSheet from "src/components/molecules/progress/LogVitalSheet";
import classNames from "classnames";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";
import useQueryParams from "src/hooks/useQueryParams";

const useDebouncedScroll = (
  callback: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void,
  delay: number
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  return (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(event);
    }, delay);
  };
};

const ProgressRecordedDataPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    selectedProgressMasterId,
    manualAddFlag,
    progressReadings,
    selectedVital,
  } = useSelector((state: RootState) => state.progress);
  const [showBottomSheet, setshowBottomSheet] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = useQueryParams(location.search);
  useEffect(() => {
    fetchProgressRecordedData(page);
  }, [selectedProgressMasterId, page]);

  useEffect(() => {
    if (!selectedProgressMasterId || !selectedVital) {
      navigate("/progress/vitals");
    }
  }, [selectedProgressMasterId, selectedVital]);
  const fetchProgressRecordedData = (page: number) => {
    // dispatch(showLoader());
    setLoading(true);
    const payload = {
      readings_master_id: selectedProgressMasterId,
      page: page,
      per_page: 20,
      version: "v1",
    };
    new Promise((resolve, reject) => {
      dispatch(
        progressRedingLogsRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((result: any) => {})
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setshowBottomSheet(false);
        setLoading(false);
      });
  };
  const handleScroll = useDebouncedScroll((event) => {
    const target = event.target as HTMLDivElement;

    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    const scrolledPercentage =
      (scrollTop / (scrollHeight - clientHeight)) * 100;
    if (
      scrolledPercentage >= 70 &&
      progressReadings &&
      progressReadings.data &&
      progressReadings.data.length < progressReadings?.totalRecords
    ) {
      setPage((prev) => prev + 1);
    }
  }, 300); // 300ms debounce delay

  const onClickOfAddIcon = () => {
    setshowBottomSheet(true);
  };

  const handleOnClickOfBackButton = () => {
    if (queryParams["isSearch"] && queryParams["isSearch"] === "true") {
      navigate("/progress/vitals");
    } else {
      navigate("/progress/vitals/progress-details");
    }
  };

  return (
    <div className=" bg-background-light-blue h-screen">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div className="flex items-center justify-between mr-3">
            <Header
              backButton={true}
              rightButton={false}
              title="All Recorded Data"
              className="bg-background-light-blue"
              onClickOfBackBtn={handleOnClickOfBackButton}
            />
            {manualAddFlag == 1 && (
              <button onClick={onClickOfAddIcon}>
                <h1 className=" font-poppins text-primary text-sm font-semibold">
                  Add
                </h1>
              </button>
            )}
          </div>
        }
        component2={
          <>
            {loading && page == 1 ? (
              <div>
                <SpinnerLoader />
              </div>
            ) : (
              <div
                className="p-4 pt-0 flex flex-col gap-4 h-[calc(100dvh-65px)] overflow-auto"
                onScroll={handleScroll}
              >
                {progressReadings && progressReadings.data ? (
                  <div className=" bg-white px-4 rounded-2xl ">
                    {progressReadings.data?.map((progressReading, index) => (
                      <div
                        className={classNames(
                          index !== (progressReadings?.data?.length ?? 1) - 1
                            ? "border-b-2"
                            : "",
                          "p-3 py-5"
                        )}
                      >
                        {selectedVital?.keys === "bloodpressure" &&
                        progressReading.reading_value_data ? (
                          <>
                            {Object.keys(
                              JSON.parse(progressReading.reading_value_data)
                            ).map((key, index) => (
                              <>
                                <ProgressRecordedDataCard
                                  key={index}
                                  readingName={key.toUpperCase()}
                                  readingValue={Number(
                                    JSON.parse(
                                      progressReading.reading_value_data ?? ""
                                    )?.[key]
                                  )}
                                  recordedDate={
                                    index === 1
                                      ? progressReading.reading_datetime
                                      : ""
                                  }
                                />
                              </>
                            ))}
                          </>
                        ) : (
                          <ProgressRecordedDataCard
                            key={index}
                            readingName={progressReading.reading_name}
                            readingValue={Number(progressReading.reading_value)}
                            recordedDate={progressReading.reading_datetime}
                          />
                        )}
                      </div>
                    ))}
                    {loading && page !== 1 && (
                      <div>
                        <SpinnerLoader />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="flex justify-center font-poppins items-center text-text-primary text-sm">
                      Please add the value to log your vital.
                    </div>
                  </>
                )}
              </div>
            )}

            {showBottomSheet && selectedVital && (
              <BottomSheetSelect
                open={showBottomSheet}
                close={() => {
                  setshowBottomSheet(false);
                }}
              >
                <LogVitalSheet
                  VitalData={selectedVital}
                  onClose={() => {
                    setshowBottomSheet(false);
                    fetchProgressRecordedData(1);
                  }}
                />
              </BottomSheetSelect>
            )}
          </>
        }
      />
    </div>
  );
};
export default ProgressRecordedDataPage;
