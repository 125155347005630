import React from "react";
import Text from "src/components/atoms/Text";
import BCAVitalDescriptionCard from "src/components/molecules/progress/BCA/BCAVitalDescriptionCard";
import { BCA_KEY_PARAMETERS } from "src/constants/bca";

const KeyBCAParameters = () => {
  return (
    <div>
      <div className="p-4">
        <Text variant="headline-medium" className="font-roboto font-normal">
          {" "}
          12 key parameters to enhance your body composition
        </Text>
        <Text variant="title-medium" className="font-light text-text-secodary">
          Discover the transformative power of the MyTatva Smart Scale with our
          comprehensive data monitoring
        </Text>
      </div>
      <div className="px-4 overflow-auto flex gap-4">
        {Object.keys(BCA_KEY_PARAMETERS).map((data: any) => (
          <div>
            <BCAVitalDescriptionCard VitalData={BCA_KEY_PARAMETERS[data]} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyBCAParameters;
