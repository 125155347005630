type ThemeColors = Record<string, Record<string, string>>;

export const THEME_COLORS: ThemeColors = {
  kotak: {
    "--color-primary": "#760FB2",
    "--color-secondary": "#A855F7",
    "--color-ternary": "#10B981",
    "--color-accent": "#F43F5E",
    "--color-background": "#F2F2F2",
    "--color-text-primary": "#1F2933",
    "--color-text-secodary": "#3D4854",
    "--color-text-ternary": "#5A6774",
    "--color-text-disabled": "#9BB1C8",
    "--color-text-title": "#313131",
    "--color-text-subtitle": "#616161",
    "--color-success": "#008000",
    "--color-error": "#E54848",
    "--color-background-primary": "#ffe5dc",
    "--color-background-secondary": "#DBDBDB",
    "--color-background-ternary": "#F6EFFB",
    "--color-background-fourth": "#C7D7FE",
    "--color-background-fifth": "#d8e2f7",
    "--color-background-sixth": "#f4ecf9",
    "--color-background-light-yellow": "#FDF7D7",
    "--color-light-yellow": "#F5D737",
    "--color-yellow": "#ED9526",
    "--color-light-green": "#DBEFDC",
    "--color-dark-green": "#3D8C40",
    "--color-dark-yellow": "#938120",
    "--color-light-red": "#FADADA",
    "--color-light-gray": "#A1A1A1",
    "--color-background-lght-blue": "#F9F8FD",
    "--color-background-accent-indigo": "#EEF4FF",
    "--color-dark-red": "#B73A3A",
    "--color-extra-dark-green": "#1E4620",
    "--color-extra-dark-yellow": "#625616",
    "--color-extra-dark-gray": "#222222",
    "--color-extra-light-gray": "#E7E7E7",
    "--color-extra-light-green": "#4CAF50",
    "--color-extra-light-red": "#EF9191",
    "--color-dark-blue": "#4B4AD5",
    "--color-extra-dark-blue": "#2D3282",
    "--color-lavender": "#F6EDFF",
    "--color-darker-lavender": "#D1A3FF",
    "--color-tab-green": "#E6FAF1",
    "--color-tab-dark-green": "#1D4D38",
    "--color-button-green-primary": "#299D6B",
    "--color-button-green-secondary": "#2A805A",
    "--color-bg-result-too-high": "#FECDCA",
    "--color-text-result-too-high": "#912018",
    "--color-label-result-too-high": "#7A271A",
    "--color-line-result-too-high": "#B42318",
    "--color-bg-result-high": "#FEE4E2",
    "--color-text-result-high": "#912018",
    "--color-label-result-high": "#D92D20",
    "--color-line-result-high": "#B42318",
    "--color-bg-result-normal": "#E6FAF1",
    "--color-text-result-normal": "#054F31",
    "--color-label-result-normal": "#039855",
    "--color-line-result-normal": "#32D583",
    "--color-bg-result-low": "#FFFBEE",
    "--color-text-result-low": "#826E2D",
    "--color-label-result-low": "#E7C144",
    "--color-line-result-low": "#E7C144",
    "--color-gold-secondary": "#FEF1C7",
  },
  visit: {
    "--color-primary": "#760FB2",
    "--color-secondary": "#A855F7",
    "--color-ternary": "#10B981",
    "--color-accent": "#F43F5E",
    "--color-background": "#F2F2F2",
    "--color-text-primary": "#1F2933",
    "--color-text-secodary": "#3D4854",
    "--color-text-ternary": "#5A6774",
    "--color-text-disabled": "#9BB1C8",
    "--color-text-title": "#313131",
    "--color-text-subtitle": "#616161",
    "--color-success": "#008000",
    "--color-error": "#E54848",
    "--color-background-primary": "#b9c9e866",
    "--color-background-secondary": "#DBDBDB",
    "--color-background-ternary": "#F6EFFB",
    "--color-background-fourth": "#C7D7FE",
    "--color-background-fifth": "#d8e2f7",
    "--color-background-sixth": "#f4ecf9",
    "--color-background-light-yellow": "#FDF7D7",
    "--color-yellow": "#ED9526",
    "--color-light-yellow": "#F5D737",
    "--color-light-green": "#DBEFDC",
    "--color-dark-green": "#3D8C40",
    "--color-dark-yellow": "#938120",
    "--color-light-red": "#FADADA",
    "--color-background-lght-blue": "#F9F8FD",
    "--color-background-accent-indigo": "#EEF4FF",
    "--color-dark-red": "#B73A3A",
    "--color-light-gray": "#A1A1A1",
    "--color-extra-dark-green": "#1E4620",
    "--color-extra-dark-yellow": "#625616",
    "--color-extra-dark-gray": "#222222",
    "--color-extra-light-gray": "#E7E7E7",
    "--color-extra-light-green": "#4CAF50",
    "--color-extra-light-red": "#EF9191",
    "--color-dark-blue": "#4B4AD5",
    "--color-extra-dark-blue": "#2D3282",
    "--color-lavender": "#F6EDFF",
    "--color-darker-lavender": "#D1A3FF",
    "--color-graph-labels": "#999999",
    "--color-graph-text": "#919191",
    "--color-graph-marker": "rgba(0,0,0,0.3)",
    "--color-graph-marker-text": "#212121",
    "--color-graph-grid": "#E0E0E0",
    "--color-graph-lines": "#FAB000",
    "--color-tab-green": "#E6FAF1",
    "--color-tab-dark-green": "#1D4D38",
    "--color-button-green-primary": "#299D6B",
    "--color-button-green-secondary": "#2A805A",
    "--color-bg-result-too-high": "#FECDCA",
    "--color-text-result-too-high": "#912018",
    "--color-label-result-too-high": "#7A271A",
    "--color-line-result-too-high": "#B42318",
    "--color-bg-result-high": "#FEE4E2",
    "--color-text-result-high": "#912018",
    "--color-label-result-high": "#D92D20",
    "--color-line-result-high": "#B42318",
    "--color-bg-result-normal": "#E6FAF1",
    "--color-text-result-normal": "#054F31",
    "--color-label-result-normal": "#039855",
    "--color-line-result-normal": "#32D583",
    "--color-bg-result-low": "#FFFBEE",
    "--color-text-result-low": "#826E2D",
    "--color-label-result-low": "#E7C144",
    "--color-line-result-low": "#E7C144",
    "--color-gold-secondary": "#FEF1C7",
  },
  default: {
    "--color-primary": "#FF5722",
    "--color-secondary": "#FFC107",
    "--color-ternary": "#4CAF50",
    "--color-accent": "#2196F3",
    "--color-background": "#F2F2F2",
    "--color-text-primary": "#1F2933",
    "--color-text-secodary": "#3D4854",
    "--color-text-ternary": "#5A6774",
    "--color-text-disabled": "#9BB1C8",
    "--color-text-title": "#313131",
    "--color-text-subtitle": "#616161",
    "--color-success": "#008000",
    "--color-error": "#E54848",
    "--color-button": "#F15223",
    "--color-background-primary": "#ffe5dc",
    "--color-background-secondary": "#DBDBDB",
    "--color-background-ternary": "#F6EFFB",
    "--color-background-fourth": "#EDDAFE",
    "--color-background-fifth": "#E5CAFF",
    "--color-background-sixth": "#ffe5dc",
    "--color-background-seven": "#F9F9FF",
    "--color-background-light-yellow": "#FDF7D7",
    "--color-yellow": "#ED9526",
    "--color-light-green": "#DBEFDC",
    "--color-dark-green": "#3D8C40",
    "--color-dark-yellow": "#938120",
    "--color-light-red": "#FADADA",
    "--color-background-lght-blue": "#F9F8FD",
    "--color-background-accent-indigo": "#EEF4FF",
    "--color-dark-red": "#B73A3A",
    "--color-light-gray": "#A1A1A1",
    "--color-extra-dark-green": "#1E4620",
    "--color-extra-dark-yellow": "#625616",
    "--color-light-yellow": "#F5D737",
    "--color-extra-dark-gray": "#222222",
    "--color-extra-light-gray": "#E7E7E7",
    "--color-extra-light-green": "#4CAF50",
    "--color-extra-light-red": "#EF9191",
    "--color-dark-blue": "#4B4AD5",
    "--color-extra-dark-blue": "#2D3282",
    "--color-lavender": "#F6EDFF",
    "--color-darker-lavender": "#D1A3FF",
    "--color-graph-labels": "#999999",
    "--color-graph-text": "#919191",
    "--color-graph-marker": "rgba(0,0,0,0.3)",
    "--color-graph-marker-text": "#212121",
    "--color-graph-grid": "#E0E0E0",
    "--color-graph-lines": "#FAB000",
    "--color-tab-green": "#E6FAF1",
    "--color-tab-dark-green": "#1D4D38",
    "--color-button-green-primary": "#299D6B",
    "--color-button-green-secondary": "#2A805A",
    "--color-bg-result-too-high": "#FECDCA",
    "--color-text-result-too-high": "#912018",
    "--color-label-result-too-high": "#7A271A",
    "--color-line-result-too-high": "#B42318",
    "--color-bg-result-high": "#FEE4E2",
    "--color-text-result-high": "#912018",
    "--color-label-result-high": "#D92D20",
    "--color-line-result-high": "#B42318",
    "--color-bg-result-normal": "#E6FAF1",
    "--color-text-result-normal": "#054F31",
    "--color-label-result-normal": "#039855",
    "--color-line-result-normal": "#32D583",
    "--color-bg-result-low": "#FFFBEE",
    "--color-text-result-low": "#826E2D",
    "--color-label-result-low": "#E7C144",
    "--color-line-result-low": "#E7C144",
    "--color-red-scale-value": "#EA6D6D",
    "--color-gold-secondary": "#FEF1C7",
    "--color-progress-icon": "#A280BB",
  },
  docsta: {
    "--color-primary": "#4B4AD5",
    "--color-secondary": "#FFC107",
    "--color-ternary": "#4CAF50",
    "--color-accent": "#2196F3",
    "--color-background": "#F2F2F2",
    "--color-text-primary": "#1F2933",
    "--color-text-secodary": "#3D4854",
    "--color-text-ternary": "#5A6774",
    "--color-text-disabled": "#9BB1C8",
    "--color-text-title": "#313131",
    "--color-text-subtitle": "#616161",
    "--color-success": "#008000",
    "--color-error": "#E54848",
    "--color-button": "#4B4AD5",
    "--color-background-primary": "#ffe5dc",
    "--color-background-secondary": "#DBDBDB",
    "--color-background-ternary": "#F6EFFB",
    "--color-background-fourth": "#EDDAFE",
    "--color-background-fifth": "#E5CAFF",
    "--color-background-sixth": "#ffe5dc",
    "--color-background-seven": "#F9F9FF",
    "--color-background-light-yellow": "#FDF7D7",
    "--color-yellow": "#ED9526",
    "--color-light-green": "#DBEFDC",
    "--color-dark-green": "#3D8C40",
    "--color-dark-yellow": "#938120",
    "--color-light-red": "#FADADA",
    "--color-background-lght-blue": "#F9F8FD",
    "--color-background-accent-indigo": "#EEF4FF",
    "--color-dark-red": "#B73A3A",
    "--color-light-gray": "#A1A1A1",
    "--color-extra-dark-green": "#1E4620",
    "--color-extra-dark-yellow": "#625616",
    "--color-light-yellow": "#F5D737",
    "--color-extra-dark-gray": "#222222",
    "--color-extra-light-gray": "#E7E7E7",
    "--color-extra-light-green": "#4CAF50",
    "--color-extra-light-red": "#EF9191",
    "--color-dark-blue": "#4B4AD5",
    "--color-extra-dark-blue": "#2D3282",
    "--color-lavender": "#F6EDFF",
    "--color-darker-lavender": "#D1A3FF",
    "--color-graph-labels": "#999999",
    "--color-graph-text": "#919191",
    "--color-graph-marker": "rgba(0,0,0,0.3)",
    "--color-graph-marker-text": "#212121",
    "--color-graph-grid": "#E0E0E0",
    "--color-graph-lines": "#FAB000",
    "--color-tab-green": "#E6FAF1",
    "--color-tab-dark-green": "#1D4D38",
    "--color-button-green-primary": "#299D6B",
    "--color-button-green-secondary": "#2A805A",
    "--color-bg-result-too-high": "#FECDCA",
    "--color-text-result-too-high": "#912018",
    "--color-label-result-too-high": "#7A271A",
    "--color-line-result-too-high": "#B42318",
    "--color-bg-result-high": "#FEE4E2",
    "--color-text-result-high": "#912018",
    "--color-label-result-high": "#D92D20",
    "--color-line-result-high": "#B42318",
    "--color-bg-result-normal": "#E6FAF1",
    "--color-text-result-normal": "#054F31",
    "--color-label-result-normal": "#039855",
    "--color-line-result-normal": "#32D583",
    "--color-bg-result-low": "#FFFBEE",
    "--color-text-result-low": "#826E2D",
    "--color-label-result-low": "#E7C144",
    "--color-line-result-low": "#E7C144",
    "--color-red-scale-value": "#EA6D6D",
    "--color-gold-secondary": "#FEF1C7",
    "--color-progress-icon": "#A280BB",
  },
  goodflip: {
    "--color-primary": "#299D6B",
    "--color-secondary": "#FFC107",
    "--color-ternary": "#4CAF50",
    "--color-accent": "#2196F3",
    "--color-background": "#F2F2F2",
    "--color-text-primary": "#1F2933",
    "--color-text-secodary": "#3D4854",
    "--color-text-ternary": "#5A6774",
    "--color-text-disabled": "#9BB1C8",
    "--color-text-title": "#313131",
    "--color-text-subtitle": "#616161",
    "--color-success": "#008000",
    "--color-error": "#E54848",
    "--color-button": "#299D6B",
    "--color-background-primary": "#dcfff0",
    "--color-background-secondary": "#DBDBDB",
    "--color-background-ternary": "#EEF4FF",
    "--color-background-fourth": "#EDDAFE",
    "--color-background-fifth": "#E5CAFF",
    "--color-background-sixth": "#ffe5dc",
    "--color-background-seven": "#F2F4F7",
    "--color-background-light-yellow": "#FDF7D7",
    "--color-yellow": "#ED9526",
    "--color-light-green": "#DBEFDC",
    "--color-dark-green": "#3D8C40",
    "--color-dark-yellow": "#938120",
    "--color-light-red": "#FADADA",
    "--color-background-lght-blue": "#F9F8FD",
    "--color-background-accent-indigo": "#EEF4FF",
    "--color-dark-red": "#B73A3A",
    "--color-light-gray": "#A1A1A1",
    "--color-extra-dark-green": "#1E4620",
    "--color-extra-dark-yellow": "#625616",
    "--color-light-yellow": "#F5D737",
    "--color-extra-dark-gray": "#222222",
    "--color-extra-light-gray": "#E7E7E7",
    "--color-extra-light-green": "#4CAF50",
    "--color-extra-light-red": "#EF9191",
    "--color-dark-blue": "#4B4AD5",
    "--color-extra-dark-blue": "#2D3282",
    "--color-lavender": "#F6EDFF",
    "--color-darker-lavender": "#D1A3FF",
    "--color-graph-labels": "#999999",
    "--color-graph-text": "#919191",
    "--color-graph-marker": "rgba(0,0,0,0.3)",
    "--color-graph-marker-text": "#212121",
    "--color-graph-grid": "#E0E0E0",
    "--color-graph-lines": "#FAB000",
    "--color-tab-green": "#E6FAF1",
    "--color-tab-dark-green": "#1D4D38",
    "--color-button-green-primary": "#299D6B",
    "--color-button-green-secondary": "#2A805A",
    "--color-bg-result-too-high": "#FECDCA",
    "--color-text-result-too-high": "#912018",
    "--color-label-result-too-high": "#7A271A",
    "--color-line-result-too-high": "#B42318",
    "--color-bg-result-high": "#FEE4E2",
    "--color-text-result-high": "#912018",
    "--color-label-result-high": "#D92D20",
    "--color-line-result-high": "#B42318",
    "--color-bg-result-normal": "#E6FAF1",
    "--color-text-result-normal": "#054F31",
    "--color-label-result-normal": "#039855",
    "--color-line-result-normal": "#32D583",
    "--color-bg-result-low": "#FFFBEE",
    "--color-text-result-low": "#826E2D",
    "--color-label-result-low": "#E7C144",
    "--color-line-result-low": "#E7C144",
    "--color-red-scale-value": "#EA6D6D",
    "--color-gold-secondary": "#FEF1C7",
    "--color-progress-icon": "#299D6B",
  },
};
