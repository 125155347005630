import classNames from "classnames";
import moment from "moment";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import Button from "src/components/atoms/Button/Button";
import Calendar from "src/components/atoms/Calendar";
import ErrorText from "src/components/atoms/ErrorText";
import InputAnimatedV2 from "src/components/atoms/InputAnimatedV2";
import Text from "src/components/atoms/Text";
import TextInputAnimated from "src/components/atoms/TextInputAnimated";
import WeelPicker from "src/components/atoms/WheelPicker";
import { validateDecimal } from "src/helpers/common";
import {
  setUpdatedValue,
  updatePatientReadingsRequest,
} from "src/store/slices/progressSlice";
import { ProgressType } from "src/types/progress";

const hourSelections = {
  duration: Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  ),
};

const minuteSelections = {
  duration: Array.from({ length: 60 }, (_, i) => String(i).padStart(2, "0")),
};

const offsetSelection = {
  duration: ["AM", "PM"],
};

interface LogVitalSheetPropsType {
  VitalData: ProgressType;
  onClose: () => void;
  onSuccess?: () => void;
}

const LogVitalSheet: FC<LogVitalSheetPropsType> = ({
  VitalData,
  onClose,
  onSuccess = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonIsDisabled] = useState(true);

  const [formData, setFormData] = useState({
    selectedDate: moment(new Date()).format("DD-MM-YYYY"),
    selectedTime: moment().format("hh:mm A"),
  });
  const [modalState, setModalState] = useState({
    timeSheet: false,
    dateSheet: false,
  });
  const [vitalValue, setVitalValue] = useState({
    systolic: "",
    diastolic: "",
    height: "",
    weight: "",
    reading_value: "",
  });
  const [vitalValueChange, setVitalValueChange] = useState({
    systolic: false,
    diastolic: false,
    height: false,
    weight: false,
    reading_value: false,
  });
  const [timeFormData, setTimeFormData] = useState({
    hour: moment().format("hh"),
    minute: moment().format("mm"),
    offset: moment().format("A"),
  });
  const [selectedDate, setSelectedDate] = useState<string>(
    formData.selectedDate
  );
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (validateCurrentVital()) {
      setIsButtonIsDisabled(false);
    } else {
      setIsButtonIsDisabled(true);
    }
  }, [
    vitalValue.diastolic,
    vitalValue.systolic,
    vitalValue.height,
    vitalValue.weight,
    vitalValue.reading_value,
  ]);

  const handleOpenSheet = (sheetName: keyof typeof modalState) => {
    setModalState((prev) => ({ ...prev, [sheetName]: true }));
  };

  const handleStartTimeInputChange = (
    key: keyof typeof timeFormData,
    value: string | number
  ) => {
    setTimeFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleCloseSheet = (sheetName: keyof typeof modalState) =>
    setModalState((prev) => ({ ...prev, [sheetName]: false }));

  const handleInputChange = (
    key: keyof typeof formData,
    value: string | number
  ) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleVitalValueChange = (
    key: keyof typeof vitalValue,
    value: string
  ) => {
    setShowError(true);
    setVitalValueChange((pre) => ({ ...pre, [key]: true }));
    setVitalValue((pre) => ({ ...pre, [key]: value }));
  };

  const disabledDate = useCallback(
    (current: any) => current && current.valueOf() > Date.now(),
    []
  );

  const validateVital = (key: keyof typeof vitalValue) => {
    switch (key) {
      case "systolic":
        return (
          vitalValue.systolic.length > 0 &&
          !Number.isNaN(Number(vitalValue.systolic)) &&
          Number(vitalValue.systolic) >=
            Number(
              JSON.parse(VitalData.validation)?.["systolic"]["min_limit"] ?? "0"
            ) &&
          Number(vitalValue.systolic) <=
            Number(
              JSON.parse(VitalData.validation)?.["systolic"]["max_limit"] ??
                "1000"
            )
        );
      case "diastolic":
        return (
          vitalValue.diastolic.length > 0 &&
          !Number.isNaN(Number(vitalValue.diastolic)) &&
          Number(vitalValue.diastolic) >=
            Number(
              JSON.parse(VitalData.validation)?.["diastolic"]["min_limit"] ??
                "0"
            ) &&
          Number(vitalValue.diastolic) <=
            Number(
              JSON.parse(VitalData.validation)?.["diastolic"]["max_limit"] ??
                "1000"
            )
        );
      case "height":
        return (
          vitalValue.height.length > 0 &&
          !Number.isNaN(Number(vitalValue.height)) &&
          Number(vitalValue.height) >=
            Number(
              JSON.parse(VitalData.validation)?.["height"]["min_limit"] ?? "0"
            ) &&
          Number(vitalValue.height) <=
            Number(
              JSON.parse(VitalData.validation)?.["height"]["max_limit"] ??
                "1000"
            )
        );
      case "weight":
        return (
          vitalValue.weight.length > 0 &&
          !Number.isNaN(Number(vitalValue.weight)) &&
          Number(vitalValue.weight) >=
            Number(
              JSON.parse(VitalData.validation)?.["weight"]["min_limit"] ?? "0"
            ) &&
          Number(vitalValue.weight) <=
            Number(
              JSON.parse(VitalData.validation)?.["weight"]["max_limit"] ??
                "1000"
            )
        );
      default:
        return (
          vitalValue.reading_value.length > 0 &&
          !Number.isNaN(Number(vitalValue.reading_value)) &&
          Number(vitalValue.reading_value) >=
            Number(JSON.parse(VitalData.validation)?.["min_limit"] ?? "0") &&
          Number(vitalValue.reading_value) <=
            Number(JSON.parse(VitalData.validation)?.["max_limit"] ?? "1000")
        );
    }
  };

  const onPressSubmit = async () => {
    let payload: any = {
      reading_datetime: `${moment(formData.selectedDate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      )} ${moment(formData.selectedTime, "HH:mm A").format("HH:mm:ss")}`,
      reading_id: VitalData.readings_master_id,
    };

    if (VitalData.keys === "bloodpressure") {
      if (!validateVital("systolic") || !validateVital("diastolic")) {
        setShowError(true);
        return;
      }
      payload.reading_value_data = {
        diastolic: vitalValue.diastolic,
        systolic: vitalValue.systolic,
      };
    } else if (VitalData.keys === "bmi") {
      if (!validateVital("weight") || !validateVital("height")) {
        setShowError(true);
        return;
      }
      payload.reading_value = (
        parseFloat(vitalValue.weight) /
        ((parseFloat(vitalValue.height) / 100) *
          (parseFloat(vitalValue.height) / 100))
      ).toFixed(2);
      payload.reading_value_data = {
        height: vitalValue.weight,
        weight: vitalValue.height,
      };
      payload.height_unit = "cm";
      payload.weight_unit = "kg";
    } else {
      if (!validateVital("reading_value")) {
        setShowError(true);
        return;
      }
      payload.reading_value = vitalValue.reading_value;
    }
    setLoading(true);
    await new Promise((resolve, reject) => {
      dispatch(
        updatePatientReadingsRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((data: any) => {
        let updatedData = data.data;
        console.log(updatedData);
        if (updatedData) {
          dispatch(
            setUpdatedValue({
              readings_master_id: updatedData.reading_id,
              reading_datetime: updatedData.reading_datetime,
              reading_value_data: updatedData.reading_value_data
                ? JSON.stringify(updatedData.reading_value_data)
                : undefined,
              reading_value: updatedData.reading_value,
            })
          );
          onSuccess();
        }
        onClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateCurrentVital = () => {
    switch (VitalData.keys) {
      case "bloodpressure":
        return validateVital("systolic") && validateVital("diastolic");
      case "bmi":
        return validateVital("height") && validateVital("weight");
      default:
        return validateVital("reading_value");
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();

      const activeElement = document.activeElement as HTMLElement | null;
      activeElement?.blur(); // Now TypeScript won't complain
    }
  };
  const renderErrorMessage = (
    minVal: string,
    maxValue: string,
    unit: string
  ) => {
    return (
      <p className="text-sm text-error pl-2">
        Invalid value. Enter a number between {minVal} and {maxValue} {unit}.{" "}
      </p>
    );
  };

  const renderInputField = () => {
    if (VitalData.keys === "bloodpressure") {
      return (
        <>
          <div>
            <TextInputAnimated
              id={"systolic"}
              type="text"
              unit={VitalData.unit}
              label={"Systolic"}
              onChange={(e) => {
                if (validateDecimal(e.target.value)) {
                  handleVitalValueChange("systolic", e.target.value);
                }
              }}
              value={vitalValue.systolic}
              onKeyDown={handleKeyDown}
              className="rounded-2xl"
            ></TextInputAnimated>
            {showError &&
              vitalValueChange["systolic"] &&
              !validateVital("systolic") &&
              renderErrorMessage(
                JSON.parse(VitalData.validation)?.["systolic"]["min_limit"],
                JSON.parse(VitalData.validation)?.["systolic"]["max_limit"],
                JSON.parse(VitalData.validation)?.["systolic"]["unit"]
              )}
          </div>
          <div>
            <TextInputAnimated
              id={"diastolic"}
              type="number"
              unit={VitalData.unit}
              label={"Diastolic"}
              onChange={(e) => {
                if (validateDecimal(e.target.value)) {
                  handleVitalValueChange("diastolic", e.target.value);
                }
              }}
              value={vitalValue.diastolic}
              onKeyDown={handleKeyDown}
              className="rounded-2xl"
            ></TextInputAnimated>
            {showError &&
              vitalValueChange["diastolic"] &&
              !validateVital("diastolic") &&
              renderErrorMessage(
                JSON.parse(VitalData.validation)?.["diastolic"]["min_limit"],
                JSON.parse(VitalData.validation)?.["diastolic"]["max_limit"],
                JSON.parse(VitalData.validation)?.["diastolic"]["unit"]
              )}
          </div>
        </>
      );
    } else if (VitalData.keys === "bmi") {
      return (
        <>
          <div>
            <TextInputAnimated
              id={"height"}
              type="text"
              unit={"cm"}
              label={"Height"}
              onChange={(e) => {
                if (validateDecimal(e.target.value)) {
                  handleVitalValueChange("height", e.target.value);
                }
              }}
              onKeyDown={handleKeyDown}
              value={vitalValue.height}
              className="rounded-2xl"
            ></TextInputAnimated>
            {showError &&
              vitalValueChange["height"] &&
              !validateVital("height") &&
              renderErrorMessage(
                JSON.parse(VitalData.validation)?.["height"]["min_limit"],
                JSON.parse(VitalData.validation)?.["height"]["max_limit"],
                JSON.parse(VitalData.validation)?.["height"]["unit"]
              )}
          </div>
          <div>
            <TextInputAnimated
              id={"weight"}
              type="text"
              unit={"kg"}
              label={"Weight"}
              onChange={(e) => {
                if (validateDecimal(e.target.value)) {
                  handleVitalValueChange("weight", e.target.value);
                }
              }}
              onKeyDown={handleKeyDown}
              value={vitalValue.weight}
              className="rounded-2xl"
            ></TextInputAnimated>
            {showError &&
              !validateVital("weight") &&
              vitalValueChange["weight"] &&
              renderErrorMessage(
                JSON.parse(VitalData.validation)?.["weight"]["min_limit"],
                JSON.parse(VitalData.validation)?.["weight"]["max_limit"],
                JSON.parse(VitalData.validation)?.["weight"]["unit"]
              )}
          </div>
        </>
      );
    } else {
      return (
        <div>
          <TextInputAnimated
            id={"reading_value"}
            type="text"
            unit={VitalData.unit}
            label={VitalData.reading_name}
            onChange={(e) => {
              if (validateDecimal(e.target.value)) {
                handleVitalValueChange("reading_value", e.target.value);
              }
            }}
            onKeyDown={handleKeyDown}
            value={vitalValue.reading_value}
            className="rounded-2xl"
          ></TextInputAnimated>
          {showError &&
            !validateVital("reading_value") &&
            vitalValueChange["reading_value"] &&
            renderErrorMessage(
              JSON.parse(VitalData.validation)?.["min_limit"],
              JSON.parse(VitalData.validation)?.["max_limit"],
              VitalData.unit
            )}
        </div>
      );
    }
  };

  return (
    <div>
      <section className="p-4">
        <h1 className="text-xl font-bold text-text-secondary">
          Add {VitalData.reading_name}
        </h1>
      </section>
      <hr className="w-full h-0.5 bg-background" />
      <section className="p-4 flex flex-col gap-3 pb-5">
        <InputAnimatedV2
          id="vitalDate"
          label="Select a Date"
          value={moment(formData.selectedDate, "DD-MM-YYYY").format(
            "DD-MM-YYYY"
          )}
          handleOnClick={() => {
            setSelectedDate(formData.selectedDate);
            handleOpenSheet("dateSheet");
          }}
        />
        <InputAnimatedV2
          id="startTime"
          label="Select Time"
          value={formData.selectedTime}
          handleOnClick={() => handleOpenSheet("timeSheet")}
        />

        {renderInputField()}
        {VitalData.standard_info && (
          <div className="bg-color-extra-light-gray text-sm p-4 text-color-light-gray rounded-2xl border border-color-light-gray">
            <p>{`Disclaimer : ${VitalData.standard_info}`} </p>
          </div>
        )}

        <div>
          <Button
            label={!loading ? "Add" : ""}
            type="submit"
            className={classNames(
              "w-full border-none",
              isButtonDisabled
                ? "!bg-gray-300 border !border-gray-300 !text-gray-500"
                : ""
            )}
            loading={loading}
            disabled={loading}
            onClick={() => {
              onPressSubmit();
            }}
          ></Button>
        </div>
      </section>

      {modalState.dateSheet && (
        <BottomSheetSelect
          open={modalState.dateSheet}
          close={() => handleCloseSheet("dateSheet")}
        >
          <section className="p-4">
            <h1 className="text-xl font-bold text-text-secodary">
              Select a Date
            </h1>
          </section>

          <hr className="w-full h-[2px] bg-background" />

          <section className="">
            <section className="relative h-full flex items-center justify-center pt-4">
              <div className=" flex gap-10 items-center justify-center">
                <Calendar
                  selectedDate={moment(selectedDate, "DD-MM-YYYY").toDate()}
                  onSelect={(date) => {
                    setSelectedDate(moment(date).format("DD-MM-YYYY"));
                  }}
                  showExpandArrow={false}
                  fullView={true}
                  className="bg-white"
                  disabledDate={disabledDate}
                />
              </div>
            </section>

            <div className="p-4">
              <Button
                label="Select Date"
                type="submit"
                className="w-full !bg-color-button border-none"
                onClick={() => {
                  handleInputChange(
                    "selectedDate",
                    moment(selectedDate, "DD-MM-YYYY").format("DD-MM-YYYY")
                  );
                  handleCloseSheet("dateSheet");
                }}
              />
            </div>
          </section>
        </BottomSheetSelect>
      )}

      {modalState.timeSheet && (
        <BottomSheetSelect
          open={modalState.timeSheet}
          close={() => handleCloseSheet("timeSheet")}
        >
          <section className="p-4">
            <h1 className="text-xl font-bold text-text-secodary">
              Select Time
            </h1>
          </section>

          <hr className="w-full h-[2px] bg-background" />

          <section className="">
            <section className="relative h-[35vh] flex items-center justify-center">
              <div className=" flex gap-10 items-center justify-center">
                <div>
                  <p className="text-text-subtitle text-xl font-medium">
                    Hours
                  </p>
                  <WeelPicker
                    selections={hourSelections}
                    value={{ duration: timeFormData.hour }}
                    onChange={(value) =>
                      handleStartTimeInputChange("hour", value.duration)
                    }
                  />
                </div>
                <div>
                  <p className="text-text-subtitle text-xl font-medium">
                    Minutes
                  </p>
                  <WeelPicker
                    selections={minuteSelections}
                    value={{ duration: timeFormData.minute }}
                    onChange={(value) =>
                      handleStartTimeInputChange("minute", value.duration)
                    }
                  />
                </div>
                <div>
                  <p className="text-text-subtitle text-xl font-medium">
                    &nbsp;
                  </p>
                  <WeelPicker
                    selections={offsetSelection}
                    value={{ duration: timeFormData.offset }}
                    onChange={(value) =>
                      handleStartTimeInputChange("offset", value.duration)
                    }
                  />
                </div>
              </div>
              <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 right-[-20px]" />
              <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 left-[-20px]" />
            </section>

            <div className="p-4">
              <Button
                label="Select Time"
                type="submit"
                className="w-full !bg-color-button border-none"
                onClick={() => {
                  handleInputChange(
                    "selectedTime",
                    `${timeFormData.hour}:${timeFormData.minute} ${timeFormData.offset}`
                  );
                  handleCloseSheet("timeSheet");
                }}
              />
            </div>
          </section>
        </BottomSheetSelect>
      )}
    </div>
  );
};

export default LogVitalSheet;
