import classNames from "classnames";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Card: React.FC<Props> = ({ className, children, onClick }) => {
  return (
    <div
      className={classNames("shadow-lg rounded-lg p-2 bg-white", className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
