import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { common } from "src/types/index";
import {
  progressRedingsResponse,
  ProgressResponce,
  ProgressState,
  ProgressType,
  ReadingUpdateResponse,
  selectedProgress,
  selectedProgressMasterIdAddFlag,
  UpdatedVitalValueType,
} from "src/types/progress";

const progressInitialState: ProgressState = {
  isLoading: false,
  error: "",
  allProgress: [],
  selectedProgress: undefined,
  selectedProgressMasterId: "",
  manualAddFlag: null,
  ReadingUpdateResponseData: null,
  redirectFromSearch: false,
};

const progressSlice = createSlice({
  name: "progress",
  initialState: progressInitialState,
  reducers: {
    setRedirectFromSearch(state, action: PayloadAction<boolean>) {
      state.redirectFromSearch = action.payload;
    },
    setUpdatedValue(state, action: PayloadAction<UpdatedVitalValueType>) {
      if (state.selectedProgress && state.selectedProgress.data) {
        state.selectedProgress.data = state.selectedProgress.data.map(
          (progress) =>
            progress.readings_master_id !== action.payload.readings_master_id
              ? progress
              : !progress.reading_datetime
              ? {
                  ...progress,
                  reading_value: action.payload.reading_value
                    ? Number(action.payload.reading_value)
                    : undefined,
                  reading_datetime: action.payload.reading_datetime ?? "",
                  reading_value_data: action.payload.reading_value_data
                    ? action.payload.reading_value_data
                    : undefined,
                }
              : moment(progress.reading_datetime, "YYYY-MM-DD HH:mm:ss") <=
                moment(action.payload.reading_datetime, "YYYY-MM-DD HH:mm:ss")
              ? {
                  ...progress,
                  reading_value: action.payload.reading_value
                    ? Number(action.payload.reading_value)
                    : undefined,
                  reading_datetime: action.payload.reading_datetime ?? "",
                  reading_value_data: action.payload.reading_value_data
                    ? action.payload.reading_value_data
                    : undefined,
                }
              : progress
        );
      }
    },
    setSelectedProgress: (state, action: PayloadAction<selectedProgress>) => {
      state.selectedProgress = action.payload;
    },
    // setSeleceted
    setSelectedDetailsMasterId: (
      state,
      action: PayloadAction<selectedProgressMasterIdAddFlag>
    ) => {
      state.selectedProgressMasterId = action.payload.selectedProgressMasterId;
      state.manualAddFlag = action.payload.manualAddFlag;
    },
    setSelectedVital: (state, action: PayloadAction<ProgressType>) => {
      state.selectedVital = action.payload;
    },
    progressCategoryRequest: (
      state: ProgressState,
      action: PayloadAction<any>
    ) => {
      state.isLoading = true;
      state.error = "";
    },
    progressCategorySuccess: (
      state: ProgressState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.allProgress = action.payload.data;
      state.isLoading = true;
    },
    progressCategoryError: (
      state: any,
      { payload: error }: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = error;
    },
    progressRedingLogsRequest: (
      state: ProgressState,
      action: PayloadAction<any>
    ) => {
      state.isLoading = action.payload;
      state.error = "";
    },
    progressRedingLogsSuccess: (
      state: ProgressState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.isLoading = true;
      if (action.payload.page === 1) {
        state.progressReadings = {
          totalRecords: action.payload.data?.totalRecords ?? 0,
          data: action.payload.data?.modifiedData,
        };
      } else {
        if (state.progressReadings?.data) {
          state.progressReadings.data = [
            ...state.progressReadings?.data,
            ...action.payload.data?.modifiedData,
          ];
        }
      }
    },
    progressRedingLogsError: (
      state: any,
      { payload: error }: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = error;
    },
    updatePatientReadingsRequest: (
      state: ProgressState,
      action: PayloadAction<any>
    ) => {
      state.isLoading = true;
      state.error = "";
    },
    updatePatientReadingsSuccess: (
      state: ProgressState,
      action: PayloadAction<ReadingUpdateResponse>
    ) => {
      state.isLoading = true;
      state.ReadingUpdateResponseData = action.payload.data;
    },
    updatePatientReadingsError: (
      state: any,
      { payload: error }: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = error;
    },
  },
});

export default progressSlice.reducer;

export const {
  setRedirectFromSearch,
  setUpdatedValue,
  progressCategoryRequest,
  progressCategorySuccess,
  progressCategoryError,
  setSelectedProgress,
  setSelectedDetailsMasterId,
  progressRedingLogsRequest,
  progressRedingLogsSuccess,
  progressRedingLogsError,
  updatePatientReadingsRequest,
  updatePatientReadingsSuccess,
  updatePatientReadingsError,
  setSelectedVital,
} = progressSlice.actions;
