import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import HomePage from "./HomePage";
import DietPage from "./DietPage";
import ExercisePage from "./ExercisePage";
import InvalidPage from "./InvalidPage";
import SearchFoodPage from "./SearchFoodPage";
import SearchFoodResultPage from "./SearchFoodResultPage";
import ExerciseMoreVideoPage from "./ExerciseMoreVideoPage";
import ProgressRecordedDataPage from "./ProgressRecordedDataPage";
import DietInsightsPage from "./DietInsightsPage";
import ExerciseInsightsPage from "./ExerciseInsightsPage";
import AuthUser from "src/components/organisms/AuthUser";
import AppointmentPage from "./AppointmentPage";
import SelectHealthCoach from "./SelectCoachPage";
import BookAppointmentSlotPage from "./BookAppointmentSlotPage";
import AppointmentConfirmationScreen from "./AppointmentConfirmationScreen";
import VitalsProgressPage from "./ProgressPage";

import ProgressSection from "./ProgressSection";
import DeviceLogScreen from "./DeviceLogScreen";
import DeviceErrorPage from "./DeviceErrorPage";
import DeviceResultPage from "./DeviceResultPage";
import DeviceProvideDetails from "./DeviceProvideDetails";
import InfiniteLoader from "../components/atoms/Loader/InfiniteLoader";
import BCAHoc from "src/components/atoms/BCAWrapper";

import "../styles/main.css";
import "react-circular-progressbar/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { SearchProgressDetailsPage } from "./SearchProgressDetailsPage";
import VitalProgressDetailsPage from "./ProgressDetailsPage";
import DeviceDetailsScreen from "./DeviceDetailsScreen";

const AppRoutes: React.FC = () => {
  const WrappedDeviceListSection = BCAHoc(ProgressSection);
  const WrappedDeviceLogScreen = BCAHoc(DeviceLogScreen);
  const WrappedDeviceProvideDetails = BCAHoc(DeviceProvideDetails);
  const WrappedDeviceErrorPage = BCAHoc(DeviceErrorPage);
  const WrappedDeviceResultPage = BCAHoc(DeviceResultPage);
  return (
    <div className="">
      <div className="relative max-w-lg mx-auto h-screen">
        <Router>
          <Routes>
            <Route path="/" element={<AuthUser component={HomePage} />} />
            <Route path="/diet" element={<AuthUser component={DietPage} />} />
            <Route
              path="/diet/search-food"
              element={<AuthUser component={SearchFoodPage} />}
            />
            <Route
              path="/diet/search-food-result"
              element={<AuthUser component={SearchFoodResultPage} />}
            />
            <Route
              path="/diet/insights"
              element={<AuthUser component={DietInsightsPage} />}
            />
            <Route
              path="/exercise"
              element={<AuthUser component={ExercisePage} />}
            />
            <Route
              path="/exercise/insights"
              element={<AuthUser component={ExerciseInsightsPage} />}
            />
            <Route
              path="/exercise/more-videos"
              element={<AuthUser component={ExerciseMoreVideoPage} />}
            />
            <Route
              path="/progress/vitals"
              element={<AuthUser component={VitalsProgressPage} />}
            />
            <Route
              path="/progress/vitals/progress-details"
              element={<AuthUser component={VitalProgressDetailsPage} />}
            />
            <Route
              path="/progress/vitals/progress-details/recorded-data"
              element={<AuthUser component={ProgressRecordedDataPage} />}
            />
            <Route
              path="/progress/vitals/search-patient-log"
              element={<AuthUser component={SearchProgressDetailsPage} />}
            />
            <Route
              path="/appointment/my-appointments"
              element={<AuthUser component={AppointmentPage} />}
            />
            <Route
              path="/appointment/book-coach"
              element={<AuthUser component={SelectHealthCoach} />}
            />
            <Route
              path="/appointment/book-slot"
              element={<AuthUser component={BookAppointmentSlotPage} />}
            />
            <Route
              path="/appointment/confirm-slot"
              element={<AuthUser component={AppointmentConfirmationScreen} />}
            />
            <Route
              path="/progress/progress-device-section"
              element={<AuthUser component={WrappedDeviceListSection} />}
            />
            <Route
              path="/progress/device-logs"
              element={<AuthUser component={WrappedDeviceLogScreen} />}
            />
            <Route
              path="/progress/device-result-page"
              element={<AuthUser component={WrappedDeviceResultPage} />}
            />
            <Route
              path="/progress/device-error"
              element={<AuthUser component={WrappedDeviceErrorPage} />}
            />
            <Route
              path="/progress/device-provide-details"
              element={<AuthUser component={WrappedDeviceProvideDetails} />}
            />
            <Route
              path="/progress/device-details"
              element={<AuthUser component={DeviceDetailsScreen} />}
            />

            <Route path="*" Component={InvalidPage} />
          </Routes>
        </Router>
        <InfiniteLoader />
        <ToastContainer />
      </div>
    </div>
  );
};

export default AppRoutes;
