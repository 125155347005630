import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";

import AppRoutes from "./pages/routes";

import store from "./store";
import { initAnalytics } from "./analytics";
import reportWebVitals from "./reportWebVitals";

// Moengage Analytics initialization
initAnalytics();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <AppRoutes />
    </StoreProvider>
  </React.StrictMode>
);

reportWebVitals();
